import { Config } from '@dav/security-component-spa';
import { API, CommonState } from '@primeit/components-vue';

CommonState.registerModule('room', {
  actions: {
    SEND_TO_AGGREGATOR: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/sendToAggregator';

      const config = await Config.getInstance();

      const myInit = {
        body: params,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
          'x-dav-company-id': config.get('dav-company-id'),
        },
        response: true,
      };

      try {
        await API.post(apiName, path, myInit);
      } catch (error) {
        ///
      }
    }
  },
  namespaced: true
});