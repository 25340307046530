const enUS = {
  btn: {
    accept: 'I accept',
    access_appointment: 'Access Appointment',
    apply: 'Apply',
    back: 'Back',
    cancel: 'Cancel',
    clear: 'Clear',
    close: 'Close',
    close_room: 'Close Service',
    confirmSchedule: 'Confirm',
    cycleVideo: 'Cycle Video',
    delete: 'Delete',
    edit: 'Edit',
    filter: 'Filter',
    finish: 'Finish',
    free_attendance: 'Free Attendance',
    network: 'Test network',
    next: 'Next',
    no: "No",
    not_accept: "I don't accept",
    pay: 'Pay',
    reactivate: 'Reactivate',
    register: 'Register',
    remove: 'Remove',
    resendMailMedic: 'Resend Email',
    resendMailPacient: 'Resend Email',
    save: 'Save',
    send: 'Send',
    sendFile: 'Send File',
    show_terms_use: 'View Terms of Use',
    start_attendance: 'Start',
    video: 'Back to video',
    waitingroom: 'Waiting Room',
    yes: 'Yes',
  },
  btnTitles: {
    background_img: 'Background image',
    blur_off: 'Blur Background',
    blur_on: 'Turn off Blur',
    chat_close: 'Close Messages / Files',
    chat_open: 'Open Messages / Files',
    countTimeAttendance: 'Session Duration',
    dismiss_patient: 'Dismiss the Patient',
    leave: 'End Attendance',
    mic_off: 'Connect Microphone',
    mic_on: 'Turn off Microphone',
    more_options: 'More Options',
    prescription: 'Prescription',
    print: 'Send Screen Photo',
    rec_on: 'Start Recording',
    rec_stop: 'End Recording',
    send: 'Send File',
    share: 'Share Screen',
    stop_share: 'Stop Screen Share',
    video_off: 'Connect Video',
    video_on: 'Turn off Video',
    video_options: 'Video Options',
  },
  country: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Netherlands Antilles',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'AmericanSamoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Aland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia, Plurinational State of',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo, The Democratic Republic of the Congo',
    CF: 'Central African Republic',
    CG: 'Congo',
    CH: 'Switzerland',
    CI: "Cote d'Ivoire",
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronesia, Federated States of Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran, Islamic Republic of Persian Gulf',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: "Korea, Democratic People's Republic of Korea",
    KR: 'Korea, Republic of South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libyan Arab Jamahiriya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'Saint Martin',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territory, Occupied',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena, Ascension and Tristan Da Cunha',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SY: 'Syrian Arab Republic',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania, United Republic of Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Holy See (Vatican City State)',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela, Bolivarian Republic of Venezuela',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  label: {
    actions: 'Actions',
    additional_minute_recorded_allowed: 'Allow additional',
    additional_minute_value: 'Additional Value',
    additional_minutes_allowed: 'Allow additional',
    additional_recorded_minute_value: 'Additional Value',
    address: 'Address',
    adress: 'Address',
    ai_observations: 'AI Observations',
    artificial_intelligence: 'Artificial Intelligence',
    attendance_average_minutes: 'Average Mining Count',
    attendance_average_minutes_recorded: 'Average Recording Minutes',
    attendance_average_minutos: 'Average frequency minutes',
    attendance_average_minutos_recorded: 'Average frequency minutes recorded',
    attendance_message: 'You will be attended to by {0}',
    attendance_schedule: 'Appointment time',
    attendance_value: 'Appointment time',
    audio: 'Audio',
    bill_type: 'Type',
    birth_date: 'Date of Birth',
    business_contact: 'Business contact',
    camera: 'Camera',
    cell_phone: 'Mobile',
    chat: 'Chat',
    cid10: 'Cid10',
    city: 'City',
    close: 'Close',
    cnpj: 'CNPJ',
    comission_type: 'Commission type',
    comission_value: 'Commission amount',
    comments: 'Comments',
    commission: 'Commission',
    company_id: 'Company',
    complement: 'Complement',
    connections: 'Session details',
    consultation_time: 'Total consultation duration',
    consultation_time_doctor: 'Total professional time in the care room',
    consultation_time_patient: 'Total patient time in the care room',
    cpf: 'CPF',
    credit_card: 'Credit Card',
    credit_card_security_code: 'CVV',
    crm: 'CRM',
    crmState: 'UF do CRM',
    DadosPessoais: 'Personal Data',
    date: 'Date',
    date_valid_credit_card: 'Credit Card Validity',
    days: 'Days',
    description: 'Description',
    doctor: 'Professional (s)',
    doctor_id: 'Professional',
    duration: 'Duration',
    duration_days: 'Duration Days',
    edit: 'Edit',
    emergency_back_home: 'Homepage',
    end_and_accomplish: 'Exit and Finish',
    end_appointment_question: 'Do you want to leave and finish the service?',
    end_date: 'End Date',
    end_date_time: 'End Date / Time',
    end_out: 'Just leave',
    end_time: 'End Time',
    end_time_waiting_room_attendance: 'The appointment time has passed by:',
    endereco: 'Address',
    entered: 'Entered',
    error_prescription: 'Prescription Unavailable',
    expiration_date: 'Expiration Date',
    field_required: '* Field Required',
    file: 'Files',
    foto: 'Foto',
    gender: 'Sex',
    goodAfternoon: 'Good Afternoon',
    goodEvening: 'Good Evening',
    goodMorning: 'Good Morning',
    guidelines: 'Guidelines',
    hello: 'Hello,',
    helloHeader: 'Hello',
    history: 'History',
    history_item_type_APPOINTMENT: 'Attendance',
    history_item_type_DIAGNOSTIC: 'Exam',
    history_item_type_MEDICAL_REFERRAL: 'Medical referral',
    hours: 'Hours',
    hypotheses: 'Hypotheses',
    hypothesis: 'Hypothesis',
    id: 'id',
    initial_attendance_quantity: 'Initial Service Quantity',
    item: 'Item',
    laboratory: 'Laboratory',
    late_person_msg: "Patient {0} has joined, we've got still {1} minute(s) left, let's make it count!",
    late_person_snk: "We've got still {0} minute(s) left, let's make it count!",
    left: 'Out',
    limit: 'Limit',
    mail: 'E-mail',
    make_your_registration: 'Make your registration',
    max_additional_minute: 'Maximum Additional',
    max_additional_recorded_minute: 'Maximum Additional',
    max_attendance_minutes: 'Maximum participation minutes',
    medical_assistent: 'Assistant Physician',
    medical_specialty: 'Specialty',
    medicines: 'Medicines',
    message: 'Message',
    messages: 'Messages',
    messages_history: 'Message History',
    mic: 'Microphone',
    minutes: 'Minutes',
    minutes_consultation: 'Answering Minutes',
    minutes_quantity: 'Number of minutes',
    minutes_value: 'Minutes value',
    months: 'month',
    more_details: 'More Details',
    more_observations: 'More observations',
    name: 'Name',
    name_card: 'Full name (Same as written on the card)',
    neighborhood: 'Bairro',
    no_data_available: 'No data available',
    notes: 'Notes',
    notes_appointment: 'Service notes',
    nps_finish_seconds: 'In {0} seconds you will be redirected to home. If you prefer, just click on the butoon bellow',
    number: 'Number',
    number_card: 'Credit card number',
    one_file: 'File',
    orientation: 'Guidelines to Patient',
    other_participant: 'Other Participants',
    out: 'Exit',
    out_appointment_question: 'Do you want to leave the service?',
    parent: 'Parent Company',
    parent_id: 'Responsible',
    participants: 'Participants',
    patient: 'Patient',
    patient_history: "Patient's history",
    patient_id: 'Patient (s)',
    payment: 'Payment',
    payment_has_local: 'Make payment at the appointment location',
    payment_method: 'Payment Method',
    personaldata: 'Personal Data',
    plan: 'Plan',
    plan_id: 'plan ID',
    plandata: 'Plan Data',
    please_fill_address: "Fill in patient's address data",
    please_register: 'Fill in your data and register',
    powered_by_dav: 'Developed with ❤ by Doutor ao Vivo',
    prescription_data: 'Prescription Data',
    previous_consultations: 'Previous Consultations',
    previous_service: 'Previous Service',
    profession: 'Profession',
    professional_id: 'Doctor',
    professional_registration: 'Professional Registration',
    quantity: 'Quantity',
    ranges: 'Tracks',
    rational: 'Rational',
    reason: 'Main Complaint',
    record_start: 'Audio and video recording of participants started ...',
    record_stop: 'Stopping the participants audio and video recording ...',
    recording_consultation: 'Recording Attendance',
    recordings: 'Recordings',
    region: 'UF',
    registration: 'Registration',
    registration_state: 'Registration State',
    release: 'Release {0}',
    representative: 'Representative',
    representative_id: 'representative ID',
    result_description: 'Result',
    resume: 'Resume',
    role: 'Cargo',
    room_active: 'You are in the waiting room for your Teleconsultation.',
    satisfaction_form_title_message: 'Thank you for participating and answering our satisfaction form!',
    save_note_appointment: 'Saving Service Notes ...',
    save_orientation_appointment: 'Saving Service Guidelines to Patient ...',
    save_reason_appointment: 'Saving Service Main Complaint ...',
    saving: 'Saving ...',
    search: 'Search',
    seconds: 'Seconds',
    send: 'Send',
    size: 'Size',
    social_name: 'Social Name',
    specialty: 'Specialty',
    start_attendance: 'A Teleconsulta vai iniciar em:',
    start_date: 'Start Date',
    start_date_time: 'Start Date / Time',
    start_time: 'Start Time',
    status: 'Status',
    status_appointment: 'Appointment Status',
    street: 'Street',
    telephone: 'Telephone',
    term_of_use_accept: 'Terms of use accept',
    term_use_agree_with: 'I have read and agree with ',
    text_term_use_accept:
      'I have read and agree with <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
    text_term_use_login:
      'The {0} has updated the <b rel="noreferrer nofollow">Terms of Use</b>. We are engaged in protect our users security and privacy.',
    time: 'Time',
    title: 'Title',
    type: 'Type',
    upshot: 'Upshot',
    use_voucher: 'Do you have voucher?',
    use_voucher_value: 'Enter the voucher code',
    value: 'Value',
    value_attendance: 'Service Value',
    value_discount: 'Discount Amount',
    value_paid: 'Amount',
    video: 'Video',
    view_messages: 'View Messages',
    wait_another_participant: 'The other participant have left the room...',
    wait_other_participant:
      'Please wait for another participant to enter the room for the audio and video resources to be released',
    wait_room_subtitle: 'You are in the waiting room for your Teleconsultation',
    wait_room_subtitle2: 'You are in the waiting room for your {0} Teleconsultation',
    warning: 'Warning',
    years: 'year (s)',
    zip_code: 'CEP',
  },
  message: {
    access_denied: 'Access Denied',
    analyzes_medical_record: 'Please wait while the professional analyzes <br /> your medical record.',
    appointmentDescription: 'Care for {0} patient {1} and professional {2} on {3}',
    appointmentTitlePAT: 'Scheduled care for {0}, patient {2} and professional {1} at {3}.',
    attendance_accomplish: 'Attendance Completed by Physician',
    attendance_needs_payment: 'In order to access attendance, you should pay it.',
    attendance_not_ini: 'The attendance has not been initialized yet, please try again later',
    attendance_save_failure: 'Unable to save your schedule, please try again',
    attendance_waiting_init: 'Waiting for attendance init',
    browser_camera_stopped: 'Your camera and / or microphone has been blocked by another feature.',
    browser_cant_publish: 'Could not send video / audio.',
    browser_deny_camera:
      'Your camera and / or microphone are blocked. <br/> We need this access to the Call Center. Click on the locked camera icon <span class = "material-icons" style = "vertical-align: bottom; color: # 000;"> videocam_off </span> in your browser\'s address bar and refresh the page',
    browser_not_suport: 'This browser does not support the service room, please access from another browser.',
    chat_checkin: '{0} joined',
    chat_checkout: '{0} left',
    chooseOption: 'Choose the option you want to perform',
    confirm_sign_out: 'Are you sure?',
    connectionFail:
      'It will not be possible to enter the service room, please check if the appointment is still valid.',
    copySuccess: 'Copied successfully',
    cupom_valid: 'Discount applied successfully',
    dialog_prescription_error: 'To proceed with the prescription, register your address in your professional profile',
    dialog_prescription_error_international_address:
      'Only Brazilian addresses are allowed to generate prescription, make sure your address is located in Brazil',
    dialogDeleteRangeText: 'Are you sure you want to delete this track?',
    dialogDeleteRangeTitle: 'Confirm the deletion',
    dialogDeleteSelectText: 'Are you sure you want to delete?',
    entered_another_location: 'Dear, you were disconnected because you entered another location again.',
    file_added_successfully: 'File added successfully',
    file_delete_successfully: 'File deleted successfully',
    file_limited_size: 'The limit size of each file for upload is {0}MB',
    file_send_timeout: 'Could not send the file, please try again',
    formErrorText: 'Inconsistent data.',
    formErrorTitle: 'Error',
    formSuccessText: 'It was done successfully.',
    inactive_room:
      'Hi, your session seems to be inactive for a long time, click the button below to continue with the session, or the it will be closed in {0} seconds',
    invalid_image_format: 'Please select a valid BMP, PNG, JPEG or GIF file.',
    invalidMail: 'It must be a valid email.',
    isRequired: '{0} is required.',
    isUnique: '{0} already exists',
    isValid: '{0} is invalid.',
    make_the_payment: 'Click here to make the payment',
    maxLength: 'The {0} must be a maximum of {1} characters.',
    minLength: 'The {0} must be at least {1} characters.',
    new_checkin_participant: '{0} entered the service room',
    new_checkout_participant: '{0} left the service room',
    new_msg_participant: '{0} sent a new chat message',
    no: 'No',
    no_participants: 'No participant is in the main video, please choose the main video participant.',
    no_patient: 'There are no pacients for this appointment.',
    paid_attendance_success:
      'The payment for the consultation was successful! We sent an email with information about access to the service room',
    participant_data_problem: "There was not possible to create prescription due to participants's data problems:",
    patient_waiting_to_be_released: 'Patiente waiting to be released.',
    payment_failure: 'Payment was not accepted, check if the data provided is correct',
    payment_in_analysis: 'Payment is in analysis. We will send a notification once its confirmed',
    payment_success: 'Payment Success',
    prescription_error: 'Error while opening prescription. Please, try again later.',
    registration_unallowed: 'Patients registration is not allowed by the company',
    required_fields: 'Please make sure to respond all required fields',
    room_accomplish: 'Your appointment was finished',
    room_end: 'You have left your appointment',
    saving_prescription: 'Saving prescription file... Please wait.',
    sign_out: 'Sign Out',
    termPendingAccept: 'Terms of use of the system pending reading and acceptance.',
    transport_hypothesis_and_history: 'Transport this hypothesis and history to the notes field',
    voucher_not_apply: 'Voucher not apply',
    waiting_for_patient_to_connect: 'Waiting for patiente to connect.',
    wiping_prescription: 'Wiping prescription... Please Wait.',
    without_audio_video: 'Cam and microphone were not enabled.',
    yes: 'Yes',
    your_service_will_begin: 'Your service will begin...',
  },
  pages: {
    appointment: {
      detail: 'Appointment',
      edit: 'Edit Appointment',
      list: 'Appointments',
      list_age: 'Scheduled Appointments',
      list_all: 'All Appointments',
      list_today: "Today's Appointments",
      new: 'New Appointment',
    },
    attedanceRoom: {
      choose_many: 'Choose as many options as you want',
      having_problems: 'Are you having problems?',
      informed_professional: 'The professional was informed of your problem. We will restart your query. Please wait!',
      message_sent: 'Message sent!',
      report_problem: 'Report problem',
    },
    check_resources: {
      confirm:
        'After checking your camera and reading the recommendations. Please click on the next button to start the attendance',
      recommend: {
        battery: 'Make sure your cell phone has enough charge',
        headphones: 'If it is possible, please use headphones',
        light: 'Make sure you are in a place with proper lighting',
        noise: 'Avoid agitated and noisy places',
        polite: 'Please, be polite to the professional that will attend you',
        video: 'Verify if your camera and audio resources are working',
        wifi: 'Check if you have a good internet connection',
      },
      title: 'Orientations',
    },
    company: {
      Detail: 'Details',
      Edit: 'Edit',
      List: 'Companies',
      New: 'New Company',
    },
    doctor: {
      edit: 'Edit Profile',
    },
    doctoroffice: {
      end: 'End of Service.',
      payment: 'Payment',
      room: 'Consultório',
    },
    home: 'Home page',
    pageNotFound: {
      access_deny: 'Make sure the link to the schedule is correct.',
      title: 'Page not found',
    },
    patient: {
      edit: 'Edit Profile',
    },
    person: {
      schedule: 'Schedule Appointment',
    },
    plan: {
      detail: 'Plan',
      edit: 'Edit plan',
      list: 'Plans',
      new: 'New plan',
    },
    precall: {
      title: 'Test connection, audio and video',
    },
    professional: {
      detail: 'Professional Details',
      edit: 'Edit Professional',
      list: 'Professional',
      new: 'New Professional',
    },
    schedule: {
      attachExams:
        'If you want to attach exams, click the send button and select the desired file. Click the finish button to finish.',
      attachFiles: 'Exams added',
      date: 'Scheduling Date',
      filesSend: 'Wait ... File being sent',
      finished: 'Consultation successfully scheduled',
      hello: 'Hello',
      loadingSchedule: 'Searching for available dates and times',
      medical_specialty: 'Specialty',
      noTimes: 'No Hours Available on the Selected Day',
      notSchedule: 'No time available for the month of {0}. Please consult another month.',
      professional: 'Professional',
      reason: 'What are you feeling?',
      scheduled: 'Scheduled',
      selectDay: 'Select the day of the consultation',
      selectHourProfessional: 'Select the schedule of the professional',
      textStepConfirm: 'Scheduling',
      textStepExams: 'Your appointment has been scheduled for {0}',
      textStepExamsText1: 'with the specialist in {0}, {1}',
      textStepReason: 'Please tell us the reason for your appointment and click next.',
      textStepSelectDay: 'Choose the day and time',
      textStepSpecialty: 'Schedule your appointment simply and quickly.',
      textStepSpecialtyText1: 'Choose specialty',
      textStepSpecialtyText2: 'Choose the day and time',
      textStepSpecialtyText3: 'Briefly describe the reason for the consultation',
      textStepSpecialtyText4: 'Confirm the data',
      textStepSpecialtyText5: 'Attach exams (if any)',
      textStepSpecialtyText6: 'You can attach images or pdf',
      textStepSpecialtyText7: 'To start scheduling, choose the desired specialty below and click next.',
      textStepSpecialtyTextPayment: 'Confirm payment',
      textStepTitleConfirm: 'Confirmation',
      textStepTitleExams: 'Exams',
      textStepTitlePayment: 'Payment',
      textStepTitleReason: 'Reason',
      textStepTitleSelectDay: 'Date/Time',
      textStepTitleSpecialty: 'Specialty',
      time: 'Schedule',
    },
    screening: {
      form_title: 'Hello{0}, please respond the questions bellow to start your attendance',
      required: 'Required field',
      screening: 'Screening',
      start_attendance: 'Start Attendance',
    },
    waitingroom: {
      room: 'Waiting room',
    },
  },
  testConnectivity: {
    audio: {
      bad: 'Audio connectivity bad',
      fair: 'Audio connectivity reasonable',
      good: 'Audio connectivity good',
      testing: 'Testing the Audio',
    },
    btnTitles: {
      chat_close: 'Close Messages / Files',
      chat_open: 'Open Messages / Files',
      count_additional_time_attendance: 'Session Additional Duration',
      countTimeAttendance: 'Session Duration',
      leave: 'End Call',
      mic_off: 'Connect Microphone',
      mic_on: 'Turn off Microphone',
      more_options: 'More Options',
      prescription: 'Prescription',
      print: 'Send Screen Photo',
      rec_on: 'Start Recording',
      rec_stop: 'End Recording',
      send: 'Send File',
      share: 'Share Screen',
      video_off: 'Connect Video',
      video_on: 'Turn off Video',
    },
    connection: {
      failure: 'Connection tests failed',
      OK: 'Successfully connected to our services',
      testing: 'Testing Connection...',
    },
    error: {
      APIConnectivityError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectivityError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionNetworkError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionSessionIdError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionTokenError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      FailedMessagingServerTestError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      FailedToCreateLocalPublisher:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      FailedToObtainMediaDevices:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      helpCamera:
        '- Check your browser address bar if access to the camera is blocked <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span>, click on the icon and give permission ',
      helpCameraConfig:
        '- Check your browser settings if the camera is blocked for this application and grant permission',
      helpCameraDupliciteUse:
        '- Check if the camera is being used by another application, close them all and access again',
      helpCameraLink:
        '- For other cases, <a href="https://suporte.doutoraovivo.com.br/hc/pt-br/articles/360048709214-PERMITIR-ACESSO-A-C%C3%82MERA-NO-NAVEGADOR" target="_blank" rel="noreferrer nofollow">click here</a>',
      IncompleteSessionCredentialsError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      InitPublisherError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      InvalidOnUpdateCallback:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      LoggingServerConnectionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MediaDeviceError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MissingOpenTokInstanceError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MissingSessionCredentialsError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MissingSubscriberError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      NetworkTestError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      NoAudioCaptureDevicesError: 'We are unable to access your microphone, we need this access to the Call Center.',
      NoVideoCaptureDevicesError: 'We are unable to access your camera, we need this access to the Call Center.',
      PublishToSessionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      PublishToSessionNetworkError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      PublishToSessionNotConnectedError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      PublishToSessionPermissionOrTimeoutError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      QualityTestError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      SubscriberGetStatsError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      SubscribeToSessionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      title: 'Possible Corrections:',
      UnsupportedBrowser: 'Unsupported Internet browser',
    },
    label: {
      actions: 'Actions',
      additional_minute_recorded_allowed: 'Allow additional',
      additional_minute_value: 'Additional Value',
      additional_minutes_allowed: 'Allow additional',
      additional_recorded_minute_value: 'Additional Value',
      address: 'Address',
      adress: 'Address',
      attendance_average_minutes: 'Average Mining Count',
      attendance_average_minutes_recorded: 'Average Recording Minutes',
      attendance_average_minutos: 'Average frequency minutes',
      attendance_average_minutos_recorded: 'Average frequency minutes recorded',
      attendance_schedule: 'Appointment time',
      attendance_value: 'Valor Atendimento',
      audio: 'Audio',
      bill_type: 'Type',
      birth_date: 'Date of Birth',
      business_contact: 'Business contact',
      camera: 'Camera',
      cell_phone: 'Mobile',
      chat: 'Chat',
      cid10: 'Cid10',
      city: 'City',
      close: 'Close',
      cnpj: 'CNPJ',
      comission_type: 'Commission type',
      comission_value: 'Commission amount',
      comments: 'Comments',
      commission: 'Commission',
      company_id: 'Company',
      complement: 'Complement',
      connections: 'Session details',
      consultation_time: 'Total consultation duration',
      consultation_time_doctor: 'Total professional time in the care room',
      consultation_time_patient: 'Total patient time in the care room',
      cpf: 'CPF',
      credit_card: 'Credit Card',
      credit_card_security_code: 'CVV',
      crm: 'CRM',
      crmState: 'UF do CRM',
      DadosPessoais: 'Personal Data',
      date: 'Date',
      date_valid_credit_card: 'Credit Card Validity',
      days: 'Days',
      description: 'Description',
      doctor: 'Professional (s)',
      doctor_id: 'Professional',
      duration: 'Duration',
      duration_days: 'Duration Days',
      edit: 'Edit',
      end_and_accomplish: 'Exit and Finish',
      end_appointment_question: 'Do you want to leave and finish the service?',
      end_date: 'End Date',
      end_date_time: 'End Date / Time',
      end_out: 'Just leave',
      end_time: 'End Time',
      end_time_waiting_room_attendance: 'The appointment time has passed by:',
      endereco: 'Address',
      entered: 'Entered',
      expiration_date: 'Expiration Date',
      field_required: '* Field Required',
      file: 'Files',
      foto: 'Foto',
      gender: 'Sex',
      goodAfternoon: 'Good Afternoon',
      goodEvening: 'Good Evening',
      goodMorning: 'Good Morning',
      hello: 'Hello,',
      helloHeader: 'Hello',
      hours: 'Hours',
      id: 'id',
      initial_attendance_quantity: 'Initial Service Quantity',
      item: 'Item',
      left: 'Out',
      limit: 'Limit',
      mail: 'E-mail',
      make_your_registration: 'Make your registration',
      max_additional_minute: 'Maximum Additional',
      max_additional_recorded_minute: 'Maximum Additional',
      max_attendance_minutes: 'Maximum participation minutes',
      medical_assistent: 'Assistant Physician',
      medical_specialty: 'Specialty',
      medicines: 'Medicines',
      message: 'Message',
      messages: 'Messages',
      messages_history: 'Message History',
      mic: 'Microphone',
      minutes: 'Minutes',
      minutes_consultation: 'Answering Minutes',
      minutes_quantity: 'Number of minutes',
      minutes_value: 'Minutes value',
      months: 'month',
      more_details: 'More Details',
      name: 'Name',
      name_card: 'Full name (Same as written on the card)',
      neighborhood: 'Bairro',
      no_data_available: 'No data available',
      notes: 'Notes',
      notes_appointment: 'Service notes',
      number: 'Number',
      number_card: 'Credit card number',
      orientation: 'Guidelines to Patient',
      other_participant: 'Other Participants',
      out: 'Exit',
      out_appointment_question: 'Do you want to leave the service?',
      parent: 'Parent Company',
      parent_id: 'Responsible',
      participants: 'Participants',
      patient: 'Patient',
      patient_history: "Patient's history",
      patient_id: 'Patient (s)',
      payment: 'Payment',
      payment_method: 'Payment Method',
      personaldata: 'Personal Data',
      plan: 'Plan',
      plan_id: 'plan ID',
      plandata: 'Plan Data',
      please_fill_address: "Fill in patient's address data",
      please_register: 'Fill in your data and register',
      powered_by_dav: 'Developed with ❤ by Doutor ao Vivo',
      prescription_data: 'Prescription Data',
      profession: 'Profession',
      professional_id: 'Doctor',
      professional_registration: 'Professional Registration',
      quantity: 'Quantity',
      ranges: 'Tracks',
      reason: 'Main Complaint',
      record_start: 'Audio and video recording of participants started ...',
      record_stop: 'Stopping the participants audio and video recording ...',
      recording_consultation: 'Recording Attendance',
      recordings: 'Recordings',
      region: 'UF',
      registration: 'Registration',
      registration_state: 'Registration State',
      representative: 'Representative',
      representative_id: 'representative ID',
      resume: 'Resume',
      role: 'Cargo',
      room_active: 'You are in the waiting room for your Teleconsultation.',
      save_note_appointment: 'Saving Service Notes ...',
      save_orientation_appointment: 'Saving Service Guidelines to Patient ...',
      save_reason_appointment: 'Saving Service Main Complaint ...',
      saving: 'Saving ...',
      search: 'Search',
      seconds: 'Seconds',
      size: 'Size',
      social_name: 'Social Name',
      specialty: 'Specialty',
      start_attendance: 'A Teleconsulta vai iniciar em:',
      start_date: 'Start Date',
      start_date_time: 'Start Date / Time',
      start_time: 'Start Time',
      status: 'Status',
      status_appointment: 'Appointment Status',
      street: 'Street',
      telephone: 'Telephone',
      term_of_use_accept: 'Terms of use accept',
      term_use_agree_with: 'I have read and agree with ',
      text_term_use_accept:
        'I have read and agree with <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
      text_term_use_login:
        'The {0} has updated the <b rel="noreferrer nofollow">Terms of Use</b>. We are engaged in protect our users security and privacy.',
      time: 'Time',
      title: 'Title',
      type: 'Type',
      upshot: 'Upshot',
      use_voucher: 'Do you have a voucher?',
      use_voucher_value: 'Enter the voucher code',
      value: 'Value',
      value_attendance: 'Service Value',
      value_discount: 'Discount Amount',
      value_paid: 'Amount',
      video: 'Video',
      view_messages: 'View Messages',
      wait_another_participant: 'The other participant have left the room...',
      wait_other_participant:
        'Please wait for another participant to enter the room for the audio and video resources to be released',
      wait_room_subtitle: 'You are in the waiting room for your Teleconsultation',
      wait_room_subtitle2: 'You are in the waiting room for your {0} Teleconsultation',
      warning: 'Warning',
      years: 'year (s)',
      zip_code: 'CEP',
    },
    message: {
      access_denied: 'Access Denied',
      appointmentDescription: 'Care for {0} patient {1} and professional {2} on {3}',
      appointmentTitlePAT: 'Scheduled care for {0}, patient {2} and professional {1} at {3}.',
      attendance_accomplish: 'Attendance Completed by Physician',
      attendance_needs_payment: 'In order to access attendance, you should pay it.',
      attendance_not_ini: 'The attendance has not been initialized yet, please try again later',
      attendance_save_failure: 'Unable to save your schedule, please try again',
      attendance_waiting_init: 'Waiting for attendance init',
      browser_camera_stopped: 'Your camera and / or microphone has been blocked by another feature.',
      browser_cant_publish: 'Could not send video / audio.',
      browser_deny_camera:
        'Your camera and / or microphone are blocked. <br/> We need this access to the Call Center. Click on the locked camera icon <span class = "material-icons" style = "vertical-align: bottom; color: # 000;"> videocam_off </span> in your browser\'s address bar and refresh the page',
      browser_not_suport: 'This browser does not support the service room, please access from another browser.',
      chat_checkin: '{0} joined',
      chat_checkout: '{0} left',
      chooseOption: 'Choose the option you want to perform',
      confirm_sign_out: 'Are you sure?',
      connectionFail:
        'It will not be possible to enter the service room, please check if the appointment is still valid.',
      copySuccess: 'Copied successfully',
      cupom_valid: 'Discount applied successfully',
      dialogDeleteRangeText: 'Are you sure you want to delete this track?',
      dialogDeleteRangeTitle: 'Confirm the deletion',
      dialogDeleteSelectText: 'Are you sure you want to delete?',
      entered_another_location: 'Dear, you were disconnected because you entered another location again.',
      file_added_successfully: 'File added successfully',
      file_delete_successfully: 'File deleted successfully',
      file_limited_size: 'The limit size of each file for upload is {0}MB',
      file_send_timeout: 'Could not send the file, please try again',
      formErrorText: 'Inconsistent data.',
      formErrorTitle: 'Error',
      formSuccessText: 'It was done successfully.',
      inactive_room:
        'Hi, your session seems to be inactive for a long time, click the button below to continue with the session, or the it will be closed in {0} seconds',
      invalidMail: 'It must be a valid email.',
      isRequired: '{0} is required.',
      isUnique: '{0} already exists',
      isValid: '{0} is invalid.',
      make_the_payment: 'Click here to make the payment',
      maxLength: 'The {0} must be a maximum of {1} characters.',
      minLength: 'The {0} must be at least {1} characters.',
      new_checkin_participant: '{0} entered the service room',
      new_checkout_participant: '{0} left the service room',
      new_msg_participant: '{0} sent a new chat message',
      no: 'No',
      no_participants: 'No participant is in the main video, please choose the main video participant.',
      no_patient: 'There are no pacients for this appointment.',
      paid_attendance_success:
        'The payment for the consultation was successful! We sent an email with information about access to the service room',
      participant_data_problem: "There was not possible to create prescription due to participants's data problems:",
      payment_failure: 'Payment was not accepted, check if the data provided is correct',
      payment_in_analysis: 'Payment is in analysis. We will send a notification once its confirmed',
      payment_success: 'Payment Success',
      prescription_error: 'Error while opening prescription. Please, try again later.',
      registration_unallowed: 'Patients registration is not allowed by the company',
      room_accomplish: 'Your appointment was finished',
      room_end: 'You have left your appointment',
      saving_prescription: 'Saving prescription file... Please wait.',
      service_guideline: 'This service has the guideline(s) below. To access them during the consultation, click on the icon ✔️ in the consultation room menu.',
      sign_out: 'Sign Out',
      termPendingAccept: 'Terms of use of the system pending reading and acceptance.',
      voucher_not_apply: 'Voucher not apply',
      wiping_prescription: 'Wiping prescription... Please Wait.',
      without_audio_video: 'Cam and microphone were not enabled.',
      yes: 'Yes',
    },
    pages: {
      appointment: {
        detail: 'Appointment',
        edit: 'Edit Appointment',
        list: 'Appointments',
        list_age: 'Scheduled Appointments',
        list_all: 'All Appointments',
        list_today: "Today's Appointments",
        new: 'New Appointment',
      },
      company: {
        Detail: 'Details',
        Edit: 'Edit',
        List: 'Companies',
        New: 'New Company',
      },
      doctor: {
        edit: 'Edit Profile',
      },
      doctoroffice: {
        end: 'End of Service.',
        payment: 'Payment',
        room: 'Consultório',
      },
      home: 'Home page',
      pageNotFound: {
        access_deny: 'Make sure the link to the schedule is correct.',
        title: 'Page not found',
      },
      patient: {
        edit: 'Edit Profile',
      },
      person: {
        schedule: 'Schedule Appointment',
      },
      plan: {
        detail: 'Plan',
        edit: 'Edit plan',
        list: 'Plans',
        new: 'New plan',
      },
      precall: {
        title: 'Test connection, audio and video',
      },
      professional: {
        detail: 'Professional Details',
        edit: 'Edit Professional',
        list: 'Professional',
        new: 'New Professional',
      },
      schedule: {
        attachExams:
          'If you want to attach exams, click the send button and select the desired file. Click the finish button to finish.',
        attachFiles: 'Exams added',
        date: 'Scheduling Date',
        filesSend: 'Wait ... File being sent',
        finished: 'Consultation successfully scheduled',
        hello: 'Hello',
        loadingSchedule: 'Searching for available dates and times',
        medical_specialty: 'Specialty',
        noTimes: 'No Hours Available on the Selected Day',
        notSchedule: 'No time available for the month of {0}. Please consult another month.',
        professional: 'Professional',
        reason: 'What are you feeling?',
        scheduled: 'Scheduled',
        selectDay: 'Select the day of the consultation',
        selectHourProfessional: 'Select the schedule of the professional',
        textStepConfirm: 'Scheduling',
        textStepExams: 'Your appointment has been scheduled for {0}',
        textStepExamsText1: 'with the specialist in {0}, {1}',
        textStepReason: 'Please tell us the reason for your appointment and click next.',
        textStepSelectDay: 'Choose the day and time',
        textStepSpecialty: 'Schedule your appointment simply and quickly.',
        textStepSpecialtyText1: 'Choose specialty',
        textStepSpecialtyText2: 'Choose the day and time',
        textStepSpecialtyText3: 'Briefly describe the reason for the consultation',
        textStepSpecialtyText4: 'Confirm the data',
        textStepSpecialtyText5: 'Attach exams (if any)',
        textStepSpecialtyText6: 'You can attach images or pdf',
        textStepSpecialtyText7: 'To start scheduling, choose the desired specialty below and click next.',
        textStepSpecialtyTextPayment: 'Confirm payment',
        textStepTitleConfirm: 'Confirmation',
        textStepTitleExams: 'Exams',
        textStepTitlePayment: 'Payment',
        textStepTitleReason: 'Reason',
        textStepTitleSelectDay: 'Date/Time',
        textStepTitleSpecialty: 'Specialty',
        time: 'Schedule',
      },
      waitingroom: {
        room: 'Waiting room',
      },
    },
    testConnectivity: {
      audio: {
        bad: 'Audio connectivity bad',
        fair: 'Audio connectivity reasonable',
        good: 'Audio connectivity good',
        testing: 'Testing the Audio',
      },
      connection: {
        failure: 'Connection tests failed',
        OK: 'Successfully connected to our services',
        testing: 'Testing Connection...',
      },
      error: {
        APIConnectivityError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        ConnectivityError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        ConnectToSessionError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        ConnectToSessionNetworkError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        ConnectToSessionSessionIdError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        ConnectToSessionTokenError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        FailedMessagingServerTestError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        FailedToCreateLocalPublisher:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        FailedToObtainMediaDevices:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        helpCamera:
          '- check your browser address bar if access to the camera is blocked <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span>, click on the icon and give permission ',
        helpCameraConfig:
          '- check your browser settings if the camera is blocked for this application and grant permission',
        helpCameraDupliciteUse:
          '- check if the camera is being used by another application, close them all and access again',
        helpCameraLink:
          '- for other cases, <a href="https://suporte.doutoraovivo.com.br/hc/pt-br/articles/360048709214-PERMITIR-ACESSO-A-C%C3%82MERA-NO-NAVEGADOR" target="_blank" rel="noreferrer nofollow">click here</a>',
        IncompleteSessionCredentialsError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        InitPublisherError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        InvalidOnUpdateCallback:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        LoggingServerConnectionError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        MediaDeviceError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        MissingOpenTokInstanceError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        MissingSessionCredentialsError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        MissingSubscriberError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        NetworkTestError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        NoAudioCaptureDevicesError: 'We are unable to access your microphone, we need this access to the Call Center.',
        NoVideoCaptureDevicesError: 'We are unable to access your camera, we need this access to the Call Center.',
        PublishToSessionError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        PublishToSessionNetworkError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        PublishToSessionNotConnectedError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        PublishToSessionPermissionOrTimeoutError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        QualityTestError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        SubscriberGetStatsError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        SubscribeToSessionError:
          'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
        title: 'Possible Corrections:',
        UnsupportedBrowser: 'Unsupported Internet browser',
      },
      failureDetails: 'See details',
      video: {
        bad: 'Video connectivity bad',
        fair: 'Video connectivity reasonable',
        good: 'Video connectivity good',
        testing: 'Testing the Video',
      },
    },
    title: {
      appointment: {
        cid10: 'Inform CID10',
        description: 'Service Description',
        doctor: 'Professionals',
        doctor_id: 'Professional',
        end_date: 'End Date',
        end_date_time: 'End Date / Time of Service',
        end_time: 'End Time',
        medical_assistent: 'Assistant Physician',
        notes: 'Service Notes',
        patient_id: 'Patient',
        role: 'Participant Type',
        start_date: 'Start Date',
        start_date_time: 'Service Start Date / Time',
        start_time: 'Start Time',
        status_appointment: 'Appointment Status',
        title: 'Service Title',
        type: 'Type of Service',
        upshot: 'Inform upshot of attendance',
      },
      company: {
        birth_date: 'Date of Birth',
        business_contact: 'Business contact',
        cell_phone: 'Mobile',
        city: 'City',
        cnpj: 'CNPJ',
        comission_type: 'Commission type',
        comission_value: 'Commission amount',
        company_id: 'Company',
        complement: 'Complement',
        cpf: 'CPF',
        edit: 'Edit',
        expiration_date: 'Expiration Date',
        gender: 'Sex',
        mail: 'E-mail',
        name: 'Fantasy Name',
        neighborhood: 'Bairro',
        number: 'Number',
        parent_id: 'Person financially responsible for the patient',
        photo: 'Photo',
        plan_id: 'plan ID',
        region: 'Federal Unit',
        representative_id: 'representative ID',
        social_name: 'Social Reason',
        status: 'Status',
        street: 'Street',
        telephone: 'Telephone',
        zip_code: 'CEP',
      },
      doctor: {
        birth_date: 'Date of Birth',
        cell_phone: 'Mobile',
        city: 'City',
        company_id: 'Company',
        complement: 'Complement',
        cpf: 'CPF',
        crm: 'CRM',
        crmState: 'State of the Regional Council of Medicine',
        Filter: 'Filter',
        gender: 'Sex',
        id: 'Identifier',
        mail: 'E-mail',
        medical_specialty: 'Medical Specialty',
        name: 'Name',
        neighborhood: 'Bairro',
        number: 'Number',
        picture: 'Photo',
        region: 'Federal Unit',
        status: 'Status',
        street: 'Street',
        telephone: 'Telephone',
        zip_code: 'CEP',
      },
      patient: {
        address: 'Address',
        birth_date: 'Date of Birth',
        cell_phone: 'Mobile',
        city: 'City',
        company_id: 'Company',
        complement: 'Complement',
        cpf: 'CPF',
        gender: 'Sex',
        mail: 'E-mail',
        name: 'Name',
        neighborhood: 'Bairro',
        number: 'Number',
        parent_id: 'Person financially responsible for the patient',
        profession: "Patient's profession",
        region: 'Region',
        status: 'Status',
        street: 'Street',
        telephone: 'Telephone',
        zip_code: 'CEP',
      },
      plan: {
        additional_minute_recorded_allowed: 'Indicates whether this plan allows additional minutes for recording',
        additional_minute_value: 'Value of the additional minute for consulting the plan',
        additional_minutes_allowed: 'Indicates whether this plan allows additional minutes for consultation',
        additional_recorded_minute_value: 'Value of the additional minute of recording the plan',
        attendance_average_minutes: 'Average number of consultation minutes for contract verification',
        attendance_average_minutes_recorded: 'Average number of minutes of recording for contract verification',
        attendance_value: 'Consultation Value',
        bill_type: 'Plan type',
        commission_type: 'Commission Type',
        commission_value: 'Commission Amount',
        description: 'Plan Name',
        duration_days: 'Indicates the number of days the plan lasts',
        initial_attendance_quantity: 'Initial number of queries for this range',
        max_additional_minute: 'Maximum number of additional minutes for consultation',
        max_additional_recorded_minute: 'Maximum number of additional minutes for recording',
        max_attendance_minutes: 'Indicates the maximum duration of minutes of the consultation',
        minutes_quantity: 'Number of minutes of consultation of the plan',
        minutes_value: 'Plan consultation minute value',
        name: 'Plan Name',
        recorded_minute_value: 'Plan recording minute value',
        recorded_minutes_quantity: 'Number of minutes of recording the plan',
      },
      professional: {
        birth_date: 'Date of Birth',
        cell_phone: 'Mobile',
        city: 'City',
        company_id: 'Company',
        complement: 'Complement',
        cpf: 'CPF',
        crm: 'CRM',
        Filter: 'Filter',
        gender: 'Sex',
        id: 'Identifier',
        mail: 'E-mail',
        medical_specialty: 'Medical Specialty',
        name: 'Name',
        neighborhood: 'Bairro',
        number: 'Number',
        photo: 'Photo',
        region: 'Federal Unit',
        role: 'Job Title',
        status: 'Status',
        street: 'Street',
        telephone: 'Telephone',
        zip_code: 'CEP',
      },
    },
    value: {
      active: 'Active',
      inactive: 'Inactive',
      no: 'No',
      yes: 'Yes',
    },
  },
  title: {
    appointment: {
      cid10: 'Inform CID10',
      description: 'Service Description',
      doctor: 'Professionals',
      doctor_id: 'Professional',
      end_date: 'End Date',
      end_date_time: 'End Date / Time of Service',
      end_time: 'End Time',
      medical_assistent: 'Assistant Physician',
      notes: 'Service Notes',
      patient_id: 'Patient',
      role: 'Participant Type',
      start_date: 'Start Date',
      start_date_time: 'Service Start Date / Time',
      start_time: 'Start Time',
      status_appointment: 'Appointment Status',
      title: 'Service Title',
      type: 'Type of Service',
      upshot: 'Inform upshot of attendance',
    },
    company: {
      birth_date: 'Date of Birth',
      business_contact: 'Business contact',
      cell_phone: 'Mobile',
      city: 'City',
      cnpj: 'CNPJ',
      comission_type: 'Commission type',
      comission_value: 'Commission amount',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      edit: 'Edit',
      expiration_date: 'Expiration Date',
      gender: 'Sex',
      mail: 'E-mail',
      name: 'Fantasy Name',
      neighborhood: 'Bairro',
      number: 'Number',
      parent_id: 'Person financially responsible for the patient',
      photo: 'Photo',
      plan_id: 'plan ID',
      region: 'Federal Unit',
      representative_id: 'representative ID',
      social_name: 'Social Reason',
      status: 'Status',
      street: 'Street',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    doctor: {
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',
      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      crm: 'CRM',
      crmState: 'State of the Regional Council of Medicine',
      Filter: 'Filter',
      gender: 'Sex',
      id: 'Identifier',
      mail: 'E-mail',
      medical_specialty: 'Medical Specialty',
      name: 'Name',
      neighborhood: 'Bairro',
      number: 'Number',
      picture: 'Photo',
      region: 'Federal Unit',
      status: 'Status',
      street: 'Street',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    patient: {
      address: 'Address',
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',
      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      gender: 'Sex',
      mail: 'E-mail',
      name: 'Name',
      neighborhood: 'Bairro',
      number: 'Number',
      parent_id: 'Person financially responsible for the patient',
      profession: "Patient's profession",
      region: 'Region',
      status: 'Status',
      street: 'Street',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    plan: {
      additional_minute_recorded_allowed: 'Indicates whether this plan allows additional minutes for recording',
      additional_minute_value: 'Value of the additional minute for consulting the plan',
      additional_minutes_allowed: 'Indicates whether this plan allows additional minutes for consultation',
      additional_recorded_minute_value: 'Value of the additional minute of recording the plan',
      attendance_average_minutes: 'Average number of consultation minutes for contract verification',
      attendance_average_minutes_recorded: 'Average number of minutes of recording for contract verification',
      attendance_value: 'Consultation Value',
      bill_type: 'Plan type',
      commission_type: 'Commission Type',
      commission_value: 'Commission Amount',
      description: 'Plan Name',
      duration_days: 'Indicates the number of days the plan lasts',
      initial_attendance_quantity: 'Initial number of queries for this range',
      max_additional_minute: 'Maximum number of additional minutes for consultation',
      max_additional_recorded_minute: 'Maximum number of additional minutes for recording',
      max_attendance_minutes: 'Indicates the maximum duration of minutes of the consultation',
      minutes_quantity: 'Number of minutes of consultation of the plan',
      minutes_value: 'Plan consultation minute value',
      name: 'Plan Name',
      recorded_minute_value: 'Plan recording minute value',
      recorded_minutes_quantity: 'Number of minutes of recording the plan',
    },
    professional: {
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',
      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      crm: 'CRM',
      Filter: 'Filter',
      gender: 'Sex',
      id: 'Identifier',
      mail: 'E-mail',
      medical_specialty: 'Medical Specialty',
      name: 'Name',
      neighborhood: 'Bairro',
      number: 'Number',
      photo: 'Photo',
      region: 'Federal Unit',
      role: 'Job Title',
      status: 'Status',
      street: 'Street',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
  },
};

export default enUS;
