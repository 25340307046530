const ptBR = {
  btn: {
    accept: 'Aceito',
    access_appointment: 'Acessar Consulta',
    apply: 'Aplicar',
    back: 'Voltar',
    cancel: 'Cancelar',
    clear: 'Limpar',
    close: 'Fechar',
    close_room: 'Fechar Atendimento',
    confirmSchedule: 'Confirmar',
    cycleVideo: 'Alterar Câmera',
    delete: 'Excluir',
    edit: 'Editar',
    filter: 'Filtrar',
    finish: 'Finalizar',
    free_attendance: 'Atendimento Grátis',
    network: 'Testar Internet',
    next: 'Próximo',
    no: 'Não',
    not_accept: 'Não Aceito',
    pay: 'Pagar',
    reactivate: 'Reativar',
    register: 'Cadastre-se',
    remove: 'Remover',
    resendMailMedic: 'Reenviar E-mail',
    resendMailPacient: 'Reenviar E-mail',
    save: 'Salvar',
    send: 'Enviar',
    sendFile: 'Enviar Arquivo',
    show_terms_use: 'Ver Termos de Uso',
    start_attendance: 'Iniciar Atendimento',
    video: 'Voltar ao vídeo',
    waitingroom: 'Sala de Espera',
    yes: 'Sim',
  },
  btnTitles: {
    background_img: 'Imagem de fundo',
    blur_off: 'Desfocar Fundo',
    blur_on: 'Desligar Desfoque',
    chat_close: 'Fechar Mensagens/Arquivos',
    chat_open: 'Abrir Mensagens/Arquivos',
    countTimeAttendance: 'Duração da Consulta',
    dismiss_patient: 'Dispensar o Paciente',
    leave: 'Encerrar Consulta',
    mic_off: 'Ligar Microfone',
    mic_on: 'Desligar Microfone',
    more_options: 'Mais Opções',
    prescription: 'Prescrição',
    print: 'Enviar Foto da Tela',
    rec_on: 'Iniciar Gravação',
    rec_stop: 'Finalizar Gravação',
    send: 'Enviar Arquivo',
    share: 'Compartilhar Tela',
    stop_share: 'Parar Compartilhamento',
    video_off: 'Ligar Vídeo',
    video_on: 'Desligar Vídeo',
    video_options: 'Opções de Vídeo',
  },
  country: {
    AD: 'Andorra',
    AE: 'Emirados Árabes Unidos',
    AF: 'Afeganistão',
    AG: 'Antígua e Barbuda',
    AI: 'Anguila',
    AL: 'Albânia',
    AM: 'Armênia',
    AN: 'Antilhas Holandesas',
    AO: 'Angola',
    AQ: 'Antártica',
    AR: 'Argentina',
    AS: 'AmericanSamoa',
    AT: 'Áustria',
    AU: 'Austrália',
    AW: 'Aruba',
    AX: 'Ilhas Aland',
    AZ: 'Azerbaijão',
    BA: 'Bósnia-Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Bélgica',
    BF: 'Burkina Faso',
    BG: 'Bulgária',
    BH: 'Bahrein',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermudas',
    BN: 'Brunei Darussalam',
    BO: 'Bolívia, Estado Plurinacional de',
    BR: 'Brasil',
    BS: 'Bahamas',
    BT: 'Butão',
    BW: 'Botsuana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canadá',
    CC: 'Ilhas Cocos (Keeling)',
    CD: 'Congo, República Democrática do Congo',
    CF: 'República Centro-Africana',
    CG: 'Congo',
    CH: 'Suíça',
    CI: 'Costa do Marfim',
    CK: 'Ilhas Cook',
    CL: 'Chile',
    CM: 'Camarões',
    CN: 'China',
    CO: 'Colômbia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CX: 'Ilha de Natal',
    CY: 'Chipre',
    CZ: 'República Tcheca',
    DE: 'Alemanha',
    DJ: 'Djibouti',
    DK: 'Dinamarca',
    DM: 'Dominica',
    DO: 'República Dominicana',
    DZ: 'Argélia',
    EC: 'Equador',
    EE: 'Estônia',
    EG: 'Egito',
    ER: 'Eritréia',
    ES: 'Espanha',
    ET: 'Etiópia',
    FI: 'Finlândia',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronésia, Estados Federados da Micronésia',
    FO: 'Ilhas Faroe',
    FR: 'França',
    GA: 'Gabão',
    GB: 'Reino Unido',
    GD: 'Grenada',
    GE: 'Geórgia',
    GF: 'Guiana Francesa',
    GG: 'Guernsey',
    GH: 'Gana',
    GI: 'Gibraltar',
    GL: 'Gronelândia',
    GM: 'Gâmbia',
    GN: 'Guiné',
    GP: 'Guadalupe',
    GQ: 'Guiné Equatorial',
    GR: 'Grécia',
    GS: 'Geórgia do Sul e as Ilhas Sandwich do Sul',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guiné-Bissau',
    GY: 'Guiana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croácia',
    HT: 'Haiti',
    HU: 'Hungria',
    ID: 'Indonésia',
    IE: 'Irlanda',
    IL: 'Israel',
    IM: 'Ilha de Man',
    IN: 'Índia',
    IO: 'Território Britânico do Oceano Índico',
    IQ: 'Iraque',
    IR: 'Irã, República Islâmica do Golfo Pérsico',
    IS: 'Islândia',
    IT: 'Itália',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordânia',
    JP: 'Japão',
    KE: 'Quênia',
    KG: 'Quirguistão',
    KH: 'Camboja',
    KI: 'Kiribati',
    KM: 'Comores',
    KN: 'São Cristóvão e Névis',
    KP: 'Coréia, República Popular Democrática da Coréia',
    KR: 'Coréia, República da Coréia do Sul',
    KW: 'Kuwait',
    KY: 'Ilhas Cayman',
    KZ: 'Cazaquistão',
    LA: 'Laos',
    LB: 'Líbano',
    LC: 'Santa Lúcia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesotho',
    LT: 'Lituânia',
    LU: 'Luxemburgo',
    LV: 'Letônia',
    LY: 'Jamahiriya Árabe Líbia',
    MA: 'Marrocos',
    MC: 'Mônaco',
    MD: 'Moldávia',
    ME: 'Montenegro',
    MF: 'São Martinho',
    MG: 'Madagascar',
    MH: 'Ilhas Marshall',
    MK: 'Macedônia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongólia',
    MO: 'Macau',
    MP: 'Ilhas Marianas do Norte',
    MQ: 'Martinica',
    MR: 'Mauritânia',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldivas',
    MW: 'Malauí',
    MX: 'México',
    MY: 'Malásia',
    MZ: 'Moçambique',
    NA: 'Namíbia',
    NC: 'Nova Caledônia',
    NE: 'Níger',
    NF: 'Ilha Norfolk',
    NG: 'Nigéria',
    NI: 'Nicarágua',
    NL: 'Países Baixos',
    NO: 'Noruega',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nova Zelândia',
    OM: 'Omã',
    PA: 'Panamá',
    PE: 'Peru',
    PF: 'Polinésia Francesa',
    PG: 'Papua Nova Guiné',
    PH: 'Filipinas',
    PK: 'Paquistão',
    PL: 'Polônia',
    PM: 'São Pedro e Miquelon',
    PN: 'Pitcairn',
    PR: 'Porto Rico',
    PS: 'Território Palestino, Ocupado',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguai',
    QA: 'Qatar',
    RE: 'Reunião',
    RO: 'Romênia',
    RS: 'Sérvia',
    RU: 'Rússia',
    RW: 'Ruanda',
    SA: 'Arábia Saudita',
    SB: 'Ilhas Salomão',
    SC: 'Seychelles',
    SD: 'Sudão',
    SE: 'Suécia',
    SG: 'Cingapura',
    SH: 'Santa Helena, Ascensão e Tristão da Cunha',
    SI: 'Eslovênia',
    SJ: 'Svalbard e Jan Mayen',
    SK: 'Eslováquia',
    SL: 'Serra Leoa',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somália',
    SR: 'Suriname',
    SS: 'Sul do Sudão',
    ST: 'São Tomé e Príncipe',
    SV: 'El Salvador',
    SY: 'República Árabe da Síria',
    SZ: 'Swazilândia',
    TC: 'Ilhas Turcas e Caicos',
    TD: 'Chade',
    TG: 'Togo',
    TH: 'Tailândia',
    TJ: 'Tajiquistão',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turcomenistão',
    TN: 'Tunísia',
    TO: 'Tonga',
    TR: 'Turquia',
    TT: 'Trinidad e Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzânia, República Unida da Tanzânia',
    UA: 'Ucrânia',
    UG: 'Uganda',
    US: 'Estados Unidos',
    UY: 'Uruguai',
    UZ: 'Uzbequistão',
    VA: 'Santa Sé (Estado da Cidade do Vaticano)',
    VC: 'São Vicente e as Granadinas',
    VE: 'Venezuela, República Bolivariana da Venezuela',
    VG: 'Ilhas Virgens - Britânicas',
    VI: 'Ilhas Virgens - Estados Unidos',
    VN: 'Vietnã',
    VU: 'Vanuatu',
    WF: 'Wallis e Futuna',
    WS: 'Samoa',
    YE: 'Iêmen',
    YT: 'Mayotte',
    ZA: 'África do Sul',
    ZM: 'Zâmbia',
    ZW: 'Zimbábue',
  },
  label: {
    actions: 'Ações',
    additional_minute_recorded_allowed: 'Permite Adicional',
    additional_minute_value: 'Valor Adicional',
    additional_minutes_allowed: 'Permite Adicional',
    additional_recorded_minute_value: 'Valor Adicional',
    address: 'Endereço',
    adress: 'Endereço',
    ai_observations: 'Observações da IA',
    artificial_intelligence: 'Inteligência Artificial',
    attendance_average_minutes: 'Média Minutos Apuração',
    attendance_average_minutes_recorded: 'Média Minutos Gravação',
    attendance_average_minutos: 'Frequência média minutos',
    attendance_average_minutos_recorded: 'Frequência média minutos gravados',
    attendance_message: 'Você será atendido por {0}',
    attendance_schedule: 'Horário do atendimento',
    attendance_value: 'Valor Atendimento',
    audio: 'Audio',
    bill_type: 'Tipo',
    birth_date: ' Data de Nascimento',
    business_contact: 'Contato de negócio',
    camera: 'Câmera',
    cell_phone: 'Celular',
    chat: 'Chat',
    cid10: 'Cid10',
    city: 'Cidade',
    close: 'Fechar',
    cnpj: 'CNPJ',
    comission_type: 'Tipo de comissão',
    comission_value: 'Valor de comissão',
    comments: 'Observações',
    commission: 'Comissão',
    company_id: 'Empresa',
    complement: 'Complemento',
    connections: 'Detalhes da consulta',
    consultation_time: 'Tempo total de duração da consulta',
    consultation_time_doctor: 'Tempo total do profissional na sala de atendimento',
    consultation_time_patient: 'Tempo total do paciente na sala de atendimento',
    cpf: 'CPF',
    credit_card: 'Cartão de Crédito',
    credit_card_security_code: 'CVV',
    crm: 'CRM',
    crmState: 'UF do CRM',
    DadosPessoais: 'Dados Pessoais',
    date: 'Data',
    date_valid_credit_card: 'Validade do Cartão de Crédito',
    days: 'Dias',
    description: 'Descrição',
    doctor: 'Profissional',
    doctor_id: 'Profissional',
    duration: 'Duração',
    duration_days: 'Dias de Duração',
    edit: 'Editar',
    end_and_accomplish: 'Sair e Finalizar',
    end_appointment_question: 'Deseja sair e finalizar o atendimento?',
    end_date: 'Data Término',
    end_date_time: 'Data / Hora Término',
    end_out: 'Somente sair',
    end_time: 'Hora Término',
    end_time_waiting_room_attendance: 'A hora marcada do atendimento já passou em:',
    endereco: 'Endereço',
    entered: 'Entrou',
    error_prescription: 'Prescrição Indisponível',
    expiration_date: 'Data de Validade',
    field_required: '* Campo Obrigatório',
    file: 'Arquivos',
    foto: 'Foto',
    gender: 'Sexo',
    goodAfternoon: 'boa tarde',
    goodEvening: 'boa noite',
    goodMorning: 'bom dia',
    guidelines: 'Diretrizes',
    hello: 'Olá,',
    helloHeader: 'Olá',
    history: 'Histórico',
    history_item_type_APPOINTMENT: 'Atendimento',
    history_item_type_DIAGNOSTIC: 'Exame',
    history_item_type_MEDICAL_REFERRAL: 'Encaminhamento médico',
    hours: 'Horas',
    hypotheses: 'Hipóteses',
    hypothesis: 'Hipótese',
    id: 'id',
    initial_attendance_quantity: 'Quantidade inicial Atendimento',
    item: 'Item',
    laboratory: 'Laboratório',
    late_person_msg: 'Paciente {0} entrou, temos mais {1} minuto(s) de consulta, vamos aproveitar!',
    late_person_snk: 'Temos mais {0} minuto(s) de consulta, vamos aproveitar!',
    left: 'Saiu',
    limit: 'Limite',
    mail: 'E-mail',
    make_your_registration: 'Faça seu cadastro',
    max_additional_minute: 'Máximo Adicionais',
    max_additional_recorded_minute: 'Máximo Adicionais',
    max_attendance_minutes: 'Minutos máximos de participação',
    medical_assistent: 'Profissional Assistente',
    medical_specialty: 'Especialidade',
    medicines: 'Medicamentos',
    message: 'Mensagem',
    messages: 'Mensagens',
    messages_history: 'Histórico de Mensagens',
    mic: 'Microfone',
    minutes: 'Minutos',
    minutes_consultation: 'Atendimento Minutos',
    minutes_quantity: 'Quantidade de minutos',
    minutos_value: 'Valor de minutos',
    months: 'mês',
    more_details: 'Mais Detalhes',
    more_observations: 'Mais observações',
    name: 'Nome',
    name_card: 'Nome completo(Igual ao escrito no cartão)',
    neighborhood: 'Bairro',
    no_data_available: 'Nenhum dado a ser exibido',
    notes: 'Notas',
    notes_appointment: 'Notas sobre o atendimento',
    number: 'Número',
    number_card: 'Número do Cartão de Crédito',
    one_file: 'Arquivo',
    orientation: 'Orientações ao Paciente',
    other_participant: 'Outros Participantes',
    out: 'Sair',
    out_appointment_question: 'Deseja sair do atendimento?',
    parent: 'Empresa Pai',
    parent_id: 'Responsável',
    participants: 'Participantes',
    patient: 'Paciente',
    patient_history: 'Histórico de paciente',
    patient_id: 'Paciente(s)',
    payment: 'Pagamento',
    payment_has_local: 'Realizar o pagamento no local da consulta',
    payment_method: 'Método de Pagamento',
    personaldata: 'Dados Pessoais',
    plan: 'Plano',
    plan_id: 'ID do plano',
    plandata: 'Dados do Plano',
    please_fill_address: 'Preencha os dados de endereço do paciente',
    please_register: 'Preencha seus dados e faça seu cadastro',
    powered_by_dav: 'Desenvolvido com ❤ pelo Doutor ao Vivo',
    prescription_data: 'Medicamentos Prescritos',
    previous_consultations: 'Consultas Anteriores',
    previous_service: 'Atendimento Anterior',
    profession: 'Profissão',
    professional_id: 'Profissional',
    professional_registration: 'Registro Profissional',
    quantity: 'Quantidade',
    ranges: 'Faixas',
    rational: 'Racional',
    reason: 'Queixa Principal',
    record_start: 'Iniciado a gravação de audio e video dos participantes...',
    record_stop: 'Parando a gravação de audio e video dos participantes...',
    recording_consultation: 'Gravação Atendimento',
    recordings: 'Gravações',
    region: 'UF',
    registration: 'Registro',
    registration_state: 'UF do registro',
    release: 'Liberar {0}',
    representative: 'Representante',
    representative_id: 'ID representante',
    result_description: 'Resultado',
    resume: 'Resumo',
    role: 'Cargo',
    room_active: 'Você está na sala de espera para sua Teleconsulta.',
    satisfaction_form_title_message: 'Obrigado por participar e responder nosso formulário de satisfação!',
    save_note_appointment: 'Salvando Notas sobre o atendimento...',
    save_orientation_appointment: 'Salvando Orientações ao Paciente ...',
    save_reason_appointment: 'Salvando Queixa Principal ...',
    saving: 'Salvando...',
    search: 'Pesquisar',
    seconds: 'Segundos',
    send: 'Enviar',
    size: 'Tamanho',
    social_name: 'Nome Social',
    specialty: 'Especialidade',
    start_attendance: 'A Teleconsulta vai iniciar em:',
    start_date: 'Data Início',
    start_date_time: 'Data / Hora Início',
    start_time: 'Hora Início',
    status: 'Status',
    status_appointment: 'Status Agendamento',
    street: 'Logradouro',
    telephone: 'Telefone',
    term_of_use_accept: 'Aceite de Termos de Uso',
    term_use_agree_with: 'Li e concordo com ',
    text_term_use_accept:
      'Li e concordo com <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
    text_term_use_login:
      'A {0} atualizou os <b rel="noreferrer nofollow">Termos de Uso</b>. Estamos empenhados em proteger a privacidade e segurança dos nossos usuários.',
    time: 'Horario',
    title: 'Título',
    type: 'Tipo',
    upshot: 'Desfecho',
    use_voucher: 'Possui voucher ou vale ?',
    use_voucher_value: 'Digite o código do voucher',
    value: 'Valor',
    value_attendance: 'Valor do Atendimento',
    value_discount: 'Valor de Desconto',
    value_paid: 'Valor Total',
    video: 'Vídeo',
    view_messages: 'Visualizar Mensagens',
    wait_another_participant: 'O outro participante saiu da sala...',
    wait_other_participant:
      'Por favor, aguarde outro participante entrar na sala para os recursos de áudio e vídeo serem liberados',
    wait_room_subtitle: 'Você está na sala de espera para sua Teleconsulta',
    wait_room_subtitle2: 'Você está na sala de espera para sua Teleconsulta de {0}',
    warning: 'Aviso',
    years: 'ano(s)',
    zip_code: 'CEP',
  },
  message: {
    access_denied: 'Acesso Negado',
    analyzes_medical_record: 'Por favor, aguarde enquanto o(a) profissional analisa <br /> o seu prontuário médico.',
    appointmentDescription: 'Atendimento de {0} paciente {1} e Profissional {2} em {3}',
    appointmentTitlePAT: 'Atendimento agendado de {0}, paciente {2} e Profissional {1} em {3}.',
    attendance_accomplish: 'Atendimento Finalizado pelo Profissional',
    attendance_needs_payment: 'Para ter acesso ao teleatendimento, primeiro realize o pagamento.',
    attendance_not_ini: 'O atendimento não foi iniciado, tente novamente em instantes',
    attendance_save_failure: 'Unable to save your schedule, please try again',
    attendance_waiting_init: 'Aguardando o início do atendimento...',
    browser_camera_stopped: 'Sua câmera e/ou microfone foram bloqueados por outro recurso.',
    browser_cant_publish: 'Não foi possível enviar video/audio.',
    browser_deny_camera:
      'Sua câmera e/ou microfone estão bloqueados.<br/> Precisamos desse acesso para o Teleatendimento. Clique no ícone de câmera bloqueada <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span> na barra de endereço do seu navegador e atualize a página',
    browser_not_suport: 'Esse Browser não suporta a sala de atendimento, favor acessar de outro browser.',
    chat_checkin: '{0} entrou',
    chat_checkout: '{0} saiu',
    chooseOption: 'Escolha a opção que deseja realizar',
    confirm_sign_out: 'Realmente deseja sair?',
    connectionFail:
      'Não será possível entrar na sala de atendimento, favor verificar se o agendamento ainda está válido.',
    copySuccess: 'Copiado com sucesso',
    cupom_valid: 'Desconto aplicado com sucesso',
    dialog_prescription_error: 'Para prosseguir com a prescrição cadastre seu endereço no seu perfil de profissional',
    dialog_prescription_error_international_address:
      'Não é permitido endereço internacional para gerar uma prescrição. Certifique que o seu endereço está cadastrado no Brasil',
    dialogDeleteRangeText: 'Tem certeza de que deseja excluir esta faixa?',
    dialogDeleteRangeTitle: 'Confirme a exclusão',
    dialogDeleteSelectText: 'Tem certeza de que deseja excluir?',
    entered_another_location: 'Prezado(a) você foi desconectado porquê entrou novamente em outro local.',
    file_added_successfully: 'Arquivo adicionado com sucesso',
    file_delete_successfully: 'Arquivo excluído com sucesso',
    file_limited_size: 'O tamanho limite de cada arquivo para envio é de {0}MB',
    file_send_timeout: 'Não foi possível enviar o arquivo, favor tentar novamente',
    formErrorText: 'Dados inconsistentes.',
    formErrorTitle: 'Erro',
    formSuccessText: 'Foi realizado com sucesso.',
    inactive_room:
      'Prezado(a) a consulta parece estar inativa por muito tempo, clique no botão a baixo para continuar na consulta, ou a consulta será fechada em {0} segundos',
    invalid_image_format: 'Por favor, selecione um arquivo BMP, PNG, JPEG ou GIF válido.',
    invalidDate: 'Data de nascimento inválida.',
    invalidMail: 'Deve ser um e-mail válido.',
    isRequired: '{0} é obrigatório.',
    isUnique: '{0} já existente',
    isValid: '{0} é inválido.',
    make_the_payment: 'Clique aqui para efetuar o pagamento',
    maxLength: 'O {0} deve ter no máximo {1} caracteres.',
    minLength: 'O {0} deve ter pelo menos {1} caracteres.',
    new_checkin_participant: '{0} entrou na sala de atendimento',
    new_checkout_participant: '{0} saiu da sala de atendimento',
    new_msg_participant: '{0} enviou uma nova mensagem no chat',
    no: 'Não',
    no_participants: 'Nenhum participante está no video principal, favor escolher o participante do video principal.',
    no_patient: 'Não há paciente regitrado na consulta.',
    paid_attendance_success:
      'O pagamento da consulta foi realizado com sucesso! Enviamos um e-mail com informações sobre o acesso à sala de atendimento',
    participant_data_problem: 'Não foi possível gerar a prescrição devido à problemas com os dados dos participantes:',
    patient_waiting_to_be_released: 'Paciente aguardando ser liberado.',
    payment_failure: 'Pagamento não foi aceito, verifique se os dados informados estão corretos',
    payment_in_analysis: 'Pagamento em análise. Notificaremos quando for aprovado',
    payment_success: 'Pagamento Realizado com sucesso',
    prescription_error: 'Erro ao abrir prescrição. Por favor, tente novamente.',
    registration_unallowed: 'O cadastro de pacientes não é autorizado pela clínica',
    required_fields: 'Por favor, certifique-se de que preencheu todos os campos obrigatórios',
    room_accomplish: 'O seu atendimento foi finalizado',
    room_end: 'Você saiu do seu atendimento',
    saving_prescription: 'Estamos salvando essa prescrição nos arquivos da sala... Aguarde.',
    service_guideline: 'Este atendimento possui a(s) diretriz(es) abaixo. Para acessá-la(s) durante a consulta, clique no ícone ✔️ no menu da sala de atendimento.',
    sign_out: 'Sair',
    termPendingAccept: 'Termos de uso do sistema pendente de leitura e de aceite.',
    transport_hypothesis_and_history: 'Transporta essa hipótese e o histórico para o campo notas',
    voucher_not_apply: 'Voucher não aplicado',
    waiting_for_patient_to_connect: 'Aguardando paciente se conectar.',
    wiping_prescription: 'Estamos excluindo essa prescrição... Aguarde.',
    without_audio_video: 'Câmera e microfone não foram habilitados.',
    yes: 'Sim',
    your_service_will_begin: 'Seu atendimento já vai começar...',
  },
  pages: {
    appointment: {
      detail: 'Agendamento',
      edit: 'Editar Agendamento',
      list: 'Agendamentos',
      list_age: 'Consultas Agendadas',
      list_all: 'Todas as Consultas',
      list_today: 'Agenda Dia',
      new: 'Novo Agendamento',
    },
    attedanceRoom: {
      choose_many: 'Escolha quantas opções quiser',
      having_problems: 'Você está com problemas?',
      informed_professional: 'O profissional foi informado do seu problema. Iremos reiniciar sua consulta. Por favor, aguarde!',
      message_sent: 'Mensagem enviada!',
      report_problem: 'Reportar problema'
    },
    check_resources: {
      confirm: 'Após realizar o teste de câmera e ler as recomendações clique em próximo para iniciar o atendimento',
      recommend: {
        battery: 'Certifique-se que seu celular está com carga suficiente',
        headphones: 'Se possível, use fones de ouvido',
        light: 'Certifique-se de que a sua iluminação está boa',
        noise: 'Evite lugares agitados e com muito barulho',
        polite: 'Procure ser educado com os participantes',
        video: 'Verifique se os seus recursos de áudio e vídeo estão habilitados',
        wifi: 'De preferência esteja conectado a uma rede WiFi',
      },
      title: 'Orientações',
    },
    company: {
      Detail: 'Detalhes',
      Edit: 'Editar',
      List: 'Empresas',
      New: 'Nova Empresa',
    },
    doctor: {
      edit: 'Editar Perfil',
    },
    doctoroffice: {
      end: 'Término do Atendimento.',
      payment: 'Pagamento',
      room: 'Consultório',
    },
    home: 'Página Inicial',
    pageNotFound: {
      access_deny: 'Verifique se o link para o agendamento está correto.',
      title: 'Página não encontrada',
    },
    patient: {
      edit: 'Editar Perfil',
    },
    person: {
      schedule: 'Agendar Consulta',
    },
    plan: {
      detail: 'Plano',
      edit: 'Editar plano',
      list: 'Planos',
      new: 'Novo plano',
    },
    precall: {
      title: 'Teste de conexão, áudio e vídeo',
    },
    professional: {
      detail: 'Detalhes do Profissional',
      edit: 'Editar Profissional',
      list: 'Profissional',
      new: 'Novo Profissional',
    },
    schedule: {
      attachExams:
        'Caso queira anexar exames, clique no botão enviar e selecione o arquivo desejado. Clique no botão finalizar para concluir.',
      attachFiles: 'Exames Adicionados',
      date: 'Data',
      filesSend: 'Aguarde... Arquivo sendo enviado',
      finished: 'Consulta Agendada com sucesso',
      hello: 'Olá',
      loadingSchedule: 'Buscando datas e horários disponíveis',
      medical_specialty: 'Especialidade',
      noTimes: 'Nenhum Horário Disponível no dia Selecionado',
      notSchedule: 'Nenhum horário disponível para o mês de {0}. Por favor, consulte outro mês.',
      professional: 'Profissional',
      reason: 'O que você está sentindo?',
      scheduled: 'Horário Marcado',
      selectDay: 'Selecione o dia da consulta',
      selectHourProfessional: 'Selecione o horário do profissional',
      textStepConfirm: 'Agendamento',
      textStepExams: 'Sua consulta foi agendada para o dia {0}',
      textStepExamsText1: 'com o especialista em {0}, {1}',
      textStepReason: 'Por favor, nos informe o motivo da consulta e clique em próximo.',
      textStepSelectDay: 'Escolha o dia e horário',
      textStepSpecialty: 'Agende sua consulta de forma simples e rápida.',
      textStepSpecialtyText1: 'Escolha a especialidade',
      textStepSpecialtyText2: 'Escolha o dia e horário',
      textStepSpecialtyText3: 'Descreva brevemente o motivo da consulta',
      textStepSpecialtyText4: 'Confirme os dados',
      textStepSpecialtyText5: 'Anexe exames (se tiver)',
      textStepSpecialtyText6: 'Você pode anexar imagens ou pdf',
      textStepSpecialtyText7:
        'Para iniciar o agendamento, escolha abaixo a especialidade desejada e clique em próximo.',
      textStepSpecialtyTextPayment: 'Realize o pagamento',
      textStepTitleConfirm: 'Confirmação',
      textStepTitleExams: 'Exames',
      textStepTitlePayment: 'Pagamento',
      textStepTitleReason: 'Motivo',
      textStepTitleSelectDay: 'Data/Horário',
      textStepTitleSpecialty: 'Especialidade',
      time: 'Horário',
    },
    screening: {
      form_title: 'Olá{0}, por favor responda as perguntas abaixo para depois iniciar o atendimento.',
      required: 'Campo obrigatório',
      screening: 'Triagem',
      start_attendance: 'Iniciar Atendimento',
    },
    waitingroom: {
      room: 'Sala de Espera',
    }
  },
  testConnectivity: {
    audio: {
      bad: 'Conectividade do áudio ruim',
      fair: 'Conectividade do áudio razoável',
      good: 'Conectividade do áudio boa',
      testing: 'Testando o Áudio',
    },
    btnTitles: {
      chat_close: 'Fechar Mensagens/Arquivos',
      chat_open: 'Abrir Mensagens/Arquivos',
      count_additional_time_attendance: 'Duração Adicional da Consulta',
      countTimeAttendance: 'Duração da Consulta',
      leave: 'Encerrar Chamada',
      mic_off: 'Ligar Microfone',
      mic_on: 'Desligar Microfone',
      more_options: 'Mais Opções',
      prescription: 'Prescrição',
      print: 'Enviar Foto da Tela',
      rec_on: 'Iniciar Gravação',
      rec_stop: 'Finalizar Gravação',
      send: 'Enviar Arquivo',
      share: 'Compartilhar Tela',
      video_off: 'Ligar Vídeo',
      video_on: 'Desligar Vídeo',
    },
    connection: {
      failure: 'Falha nos testes de conexão',
      OK: 'Conectado com sucesso em nossos serviços',
      testing: 'Testando Conexão...',
    },
    error: {
      APIConnectivityError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectivityError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionNetworkError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionSessionIdError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionTokenError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      FailedMessagingServerTestError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      FailedToCreateLocalPublisher:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      FailedToObtainMediaDevices:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      helpCamera:
        '- Verifique na barra de endereço do seu navegador se o acesso à câmera esta bloqueado <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span>, clique no ícone e dê permissão',
      helpCameraConfig:
        '- Verifique nas configurações do seu navegador se a câmera está bloqueada para esse aplicativo e conceda a permissão',
      helpCameraDupliciteUse:
        '- Verifique se a câmera está sendo utilizado por outro aplicativo, feche todos e acesse novamente',
      helpCameraLink:
        '- Para outros casos, <a href="https://suporte.doutoraovivo.com.br/hc/pt-br/articles/360048709214-PERMITIR-ACESSO-A-C%C3%82MERA-NO-NAVEGADOR" target="_blank" rel="noreferrer nofollow">clique aqui</a>',
      IncompleteSessionCredentialsError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      InitPublisherError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      InvalidOnUpdateCallback:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      LoggingServerConnectionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MediaDeviceError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MissingOpenTokInstanceError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MissingSessionCredentialsError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MissingSubscriberError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      NetworkTestError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      NoAudioCaptureDevicesError:
        'Não conseguimos acessar seu microfone, precisamos desse acesso para o Teleatendimento.',
      NoVideoCaptureDevicesError: 'Não conseguimos acessar sua câmera, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionNetworkError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionNotConnectedError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionPermissionOrTimeoutError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      QualityTestError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      SubscriberGetStatsError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      SubscribeToSessionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      title: 'Possíveis Correções:',
      UnsupportedBrowser: 'Navegador de Internet não suportado',
    },
    label: {
      actions: 'Ações',
      additional_minute_recorded_allowed: 'Permite Adicional',
      additional_minute_value: 'Valor Adicional',
      additional_minutes_allowed: 'Permite Adicional',
      additional_recorded_minute_value: 'Valor Adicional',
      address: 'Endereço',
      adress: 'Endereço',
      attendance_average_minutes: 'Média Minutos Apuração',
      attendance_average_minutes_recorded: 'Média Minutos Gravação',
      attendance_average_minutos: 'Frequência média minutos',
      attendance_average_minutos_recorded: 'Frequência média minutos gravados',
      attendance_schedule: 'Horário do atendimento',
      attendance_value: 'Valor Atendimento',
      audio: 'Audio',
      bill_type: 'Tipo',
      birth_date: ' Data de Nascimento',
      business_contact: 'Contato de negócio',
      camera: 'Câmera',
      cell_phone: 'Celular',
      chat: 'Chat',
      cid10: 'Cid10',
      city: 'Cidade',
      close: 'Fechar',
      cnpj: 'CNPJ',
      comission_type: 'Tipo de comissão',
      comission_value: 'Valor de comissão',
      comments: 'Observações',
      commission: 'Comissão',
      company_id: 'Empresa',
      complement: 'Complemento',
      connections: 'Detalhes da consulta',
      consultation_time: 'Tempo total de duração da consulta',
      consultation_time_doctor: 'Tempo total do profissional na sala de atendimento',
      consultation_time_patient: 'Tempo total do paciente na sala de atendimento',
      cpf: 'CPF',
      credit_card: 'Cartão de Crédito',
      credit_card_security_code: 'CVV',
      crm: 'CRM',
      crmState: 'UF do CRM',
      DadosPessoais: 'Dados Pessoais',
      date: 'Data',
      date_valid_credit_card: 'Validade do Cartão de Crédito',
      days: 'Dias',
      description: 'Descrição',
      doctor: 'Profissional',
      doctor_id: 'Profissional',
      duration: 'Duração',
      duration_days: 'Dias de Duração',
      edit: 'Editar',
      emergency_back_home: 'Página Inicial',
      end_and_accomplish: 'Sair e Finalizar',
      end_appointment_question: 'Deseja sair e finalizar o atendimento?',
      end_date: 'Data Término',
      end_date_time: 'Data / Hora Término',
      end_out: 'Somente sair',
      end_time: 'Hora Término',
      end_time_waiting_room_attendance: 'A hora marcada do atendimento já passou em:',
      endereco: 'Endereço',
      entered: 'Entrou',
      expiration_date: 'Data de Validade',
      field_required: '* Campo Obrigatório',
      file: 'Arquivos',
      foto: 'Foto',
      gender: 'Sexo',
      goodAfternoon: 'boa tarde',
      goodEvening: 'boa noite',
      goodMorning: 'bom dia',
      hello: 'Olá,',
      helloHeader: 'Olá',
      hours: 'Horas',
      id: 'id',
      initial_attendance_quantity: 'Quantidade inicial Atendimento',
      item: 'Item',
      left: 'Saiu',
      limit: 'Limite',
      mail: 'E-mail',
      make_your_registration: 'Faça seu cadastro',
      max_additional_minute: 'Máximo Adicionais',
      max_additional_recorded_minute: 'Máximo Adicionais',
      max_attendance_minutes: 'Minutos máximos de participação',
      medical_assistent: 'Profissional Assistente',
      medical_specialty: 'Especialidade',
      medicines: 'Medicamentos',
      message: 'Mensagem',
      messages: 'Mensagens',
      messages_history: 'Histórico de Mensagens',
      mic: 'Microfone',
      minutes: 'Minutos',
      minutes_consultation: 'Atendimento Minutos',
      minutes_quantity: 'Quantidade de minutos',
      minutos_value: 'Valor de minutos',
      months: 'mês',
      more_details: 'Mais Detalhes',
      name: 'Nome',
      name_card: 'Nome completo(Igual ao escrito no cartão)',
      neighborhood: 'Bairro',
      no_data_available: 'Nenhum dado a ser exibido',
      notes: 'Notas',
      notes_appointment: 'Notas sobre o atendimento',
      nps_finish_seconds:
        'Em {0} segundos você será redirecionado(a) para a página inicial. Se preferir, clique no botão abaixo:',
      number: 'Número',
      number_card: 'Número do Cartão de Crédito',
      orientation: 'Orientações ao Paciente',
      other_participant: 'Outros Participantes',
      out: 'Sair',
      out_appointment_question: 'Deseja sair do atendimento?',
      parent: 'Empresa Pai',
      parent_id: 'Responsável',
      participants: 'Participantes',
      patient: 'Paciente',
      patient_history: 'Histórico de paciente',
      patient_id: 'Paciente(s)',
      payment: 'Pagamento',
      payment_method: 'Método de Pagamento',
      personaldata: 'Dados Pessoais',
      plan: 'Plano',
      plan_id: 'ID do plano',
      plandata: 'Dados do Plano',
      please_fill_address: 'Preencha os dados de endereço do paciente',
      please_register: 'Preencha seus dados e faça seu cadastro',
      powered_by_dav: 'Desenvolvido com ❤ pelo Doutor ao Vivo',
      prescription_data: 'Medicamentos Prescritos',
      profession: 'Profissão',
      professional_id: 'Profissional',
      professional_registration: 'Registro Profissional',
      quantity: 'Quantidade',
      ranges: 'Faixas',
      reason: 'Queixa Principal',
      record_start: 'Iniciado a gravação de audio e video dos participantes...',
      record_stop: 'Parando a gravação de audio e video dos participantes...',
      recording_consultation: 'Gravação Atendimento',
      recordings: 'Gravações',
      region: 'UF',
      registration: 'Registro',
      registration_state: 'UF do registro',
      representative: 'Representante',
      representative_id: 'ID representante',
      resume: 'Resumo',
      role: 'Cargo',
      room_active: 'Você está na sala de espera para sua Teleconsulta.',
      save_note_appointment: 'Salvando Notas sobre o atendimento...',
      save_orientation_appointment: 'Salvando Orientações ao Paciente ...',
      save_reason_appointment: 'Salvando Queixa Principal ...',
      saving: 'Salvando...',
      search: 'Pesquisar',
      seconds: 'Segundos',
      size: 'Tamanho',
      social_name: 'Nome Social',
      specialty: 'Especialidade',
      start_attendance: 'A Teleconsulta vai iniciar em:',
      start_date: 'Data Início',
      start_date_time: 'Data / Hora Início',
      start_time: 'Hora Início',
      status: 'Status',
      status_appointment: 'Status Agendamento',
      street: 'Logradouro',
      telephone: 'Telefone',
      term_of_use_accept: 'Aceite de Termos de Uso',
      term_use_agree_with: 'Li e concordo com ',
      text_term_use_accept:
        'Li e concordo com <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
      text_term_use_login:
        'A {0} atualizou os <b rel="noreferrer nofollow">Termos de Uso</b>. Estamos empenhados em proteger a privacidade e segurança dos nossos usuários.',
      time: 'Horario',
      title: 'Título',
      type: 'Tipo',
      upshot: 'Desfecho',
      use_voucher: 'Possui voucher ou vale ?',
      use_voucher_value: 'Digite o código do voucher',
      value: 'Valor',
      value_attendance: 'Valor do Atendimento',
      value_discount: 'Valor de Desconto',
      value_paid: 'Valor Total',
      video: 'Vídeo',
      view_messages: 'Visualizar Mensagens',
      wait_another_participant: 'O outro participante saiu da sala...',
      wait_other_participant:
        'Por favor, aguarde outro participante entrar na sala para os recursos de áudio e vídeo serem liberados',
      wait_room_subtitle: 'Você está na sala de espera para sua Teleconsulta',
      wait_room_subtitle2: 'Você está na sala de espera para sua Teleconsulta de {0}',
      warning: 'Aviso',
      years: 'ano(s)',
      zip_code: 'CEP',
    },
    message: {
      access_denied: 'Acesso Negado',
      appointmentDescription: 'Atendimento de {0} paciente {1} e Profissional {2} em {3}',
      appointmentTitlePAT: 'Atendimento agendado de {0}, paciente {2} e Profissional {1} em {3}.',
      attendance_accomplish: 'Atendimento Finalizado pelo Profissional',
      attendance_needs_payment: 'Para ter acesso ao teleatendimento, primeiro realize o pagamento.',
      attendance_save_failure: 'Unable to save your schedule, please try again',
      browser_camera_stopped: 'Sua câmera e/ou microfone foram bloqueados por outro recurso.',
      browser_cant_publish: 'Não foi possível enviar video/audio.',
      browser_deny_camera:
        'Sua câmera e/ou microfone estão bloqueados.<br/> Precisamos desse acesso para o Teleatendimento. Clique no ícone de câmera bloqueada <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span> na barra de endereço do seu navegador e atualize a página',
      browser_not_suport: 'Esse Browser não suporta a sala de atendimento, favor acessar de outro browser.',
      chat_checkin: '{0} entrou',
      chat_checkout: '{0} saiu',
      chooseOption: 'Escolha a opção que deseja realizar',
      confirm_sign_out: 'Realmente deseja sair?',
      connectionFail:
        'Não será possível entrar na sala de atendimento, favor verificar se o agendamento ainda está válido.',
      copySuccess: 'Copiado com sucesso',
      cupom_valid: 'Desconto aplicado com sucesso',
      dialogDeleteRangeText: 'Tem certeza de que deseja excluir esta faixa?',
      dialogDeleteRangeTitle: 'Confirme a exclusão',
      dialogDeleteSelectText: 'Tem certeza de que deseja excluir?',
      entered_another_location: 'Prezado(a) você foi desconectado porquê entrou novamente em outro local.',
      file_added_successfully: 'Arquivo adicionado com sucesso',
      file_delete_successfully: 'Arquivo excluído com sucesso',
      file_limited_size: 'O tamanho limite de cada arquivo para envio é de {0}MB',
      file_send_timeout: 'Não foi possível enviar o arquivo, favor tentar novamente',
      formErrorText: 'Dados inconsistentes.',
      formErrorTitle: 'Erro',
      formSuccessText: 'Foi realizado com sucesso.',
      inactive_room:
        'Prezado(a) a consulta parece estar inativa por muito tempo, clique no botão a baixo para continuar na consulta, ou a consulta será fechada em {0} segundos',
      invalidDate: 'Data de nascimento inválida.',
      invalidMail: 'Deve ser um e-mail válido.',
      isRequired: '{0} é obrigatório.',
      isUnique: '{0} já existente',
      isValid: '{0} é inválido.',
      make_the_payment: 'Clique aqui para efetuar o pagamento',
      maxLength: 'O {0} deve ter no máximo {1} caracteres.',
      minLength: 'O {0} deve ter pelo menos {1} caracteres.',
      new_checkin_participant: '{0} entrou na sala de atendimento',
      new_checkout_participant: '{0} saiu da sala de atendimento',
      new_msg_participant: '{0} enviou uma nova mensagem no chat',
      no: 'Não',
      no_participants: 'Nenhum participante está no video principal, favor escolher o participante do video principal.',
      no_patient: 'Não há paciente regitrado na consulta.',
      paid_attendance_success:
        'O pagamento da consulta foi realizado com sucesso! Enviamos um e-mail com informações sobre o acesso à sala de atendimento',
      participant_data_problem:
        'Não foi possível gerar a prescrição devido à problemas com os dados dos participantes:',
      payment_failure: 'Pagamento não foi aceito, verifique se os dados informados estão corretos',
      payment_in_analysis: 'Pagamento em análise. Notificaremos quando for aprovado',
      payment_success: 'Pagamento Realizado com sucesso',
      prescription_error: 'Erro ao abrir prescrição. Por favor, tente novamente.',
      registration_unallowed: 'O cadastro de pacientes não é autorizado pela clínica',
      room_accomplish: 'O seu atendimento foi finalizado',
      room_end: 'Você saiu do seu atendimento',
      saving_prescription: 'Estamos salvando essa prescrição nos arquivos da sala... Aguarde.',
      sign_out: 'Sair',
      termPendingAccept: 'Termos de uso do sistema pendente de leitura e de aceite.',
      voucher_not_apply: 'Voucher não aplicado',
      wiping_prescription: 'Estamos excluindo essa prescrição... Aguarde.',
      without_audio_video: 'Câmera e microfone não foram habilitados.',
      yes: 'Sim',
    },
    pages: {
      appointment: {
        detail: 'Agendamento',
        edit: 'Editar Agendamento',
        list: 'Agendamentos',
        list_age: 'Consultas Agendadas',
        list_all: 'Todas as Consultas',
        list_today: 'Agenda Dia',
        new: 'Novo Agendamento',
      },
      company: {
        Detail: 'Detalhes',
        Edit: 'Editar',
        List: 'Empresas',
        New: 'Nova Empresa',
      },
      doctor: {
        edit: 'Editar Perfil',
      },
      doctoroffice: {
        end: 'Término do Atendimento.',
        payment: 'Pagamento',
        room: 'Consultório',
      },
      home: 'Página Inicial',
      pageNotFound: {
        access_deny: 'Verifique se o link para o agendamento está correto.',
        title: 'Página não encontrada',
      },
      patient: {
        edit: 'Editar Perfil',
      },
      person: {
        schedule: 'Agendar Consulta',
      },
      plan: {
        detail: 'Plano',
        edit: 'Editar plano',
        list: 'Planos',
        new: 'Novo plano',
      },
      precall: {
        title: 'Teste de conexão, áudio e vídeo',
      },
      professional: {
        detail: 'Detalhes do Profissional',
        edit: 'Editar Profissional',
        list: 'Profissional',
        new: 'Novo Profissional',
      },
      schedule: {
        attachExams:
          'Caso queira anexar exames, clique no botão enviar e selecione o arquivo desejado. Clique no botão finalizar para concluir.',
        attachFiles: 'Exames Adicionados',
        date: 'Data',
        filesSend: 'Aguarde... Arquivo sendo enviado',
        finished: 'Consulta Agendada com sucesso',
        hello: 'Olá',
        loadingSchedule: 'Buscando datas e horários disponíveis',
        medical_specialty: 'Especialidade',
        noTimes: 'Nenhum Horário Disponível no dia Selecionado',
        notSchedule: 'Nenhum horário disponível para o mês de {0}. Por favor, consulte outro mês.',
        professional: 'Profissional',
        reason: 'O que você está sentindo?',
        scheduled: 'Horário Marcado',
        selectDay: 'Selecione o dia da consulta',
        selectHourProfessional: 'Selecione o horário do profissional',
        textStepConfirm: 'Agendamento',
        textStepExams: 'Sua consulta foi agendada para o dia {0}',
        textStepExamsText1: 'com o especialista em {0}, {1}',
        textStepReason: 'Por favor, nos informe o motivo da consulta e clique em próximo.',
        textStepSelectDay: 'Escolha o dia e horário',
        textStepSpecialty: 'Agende sua consulta de forma simples e rápida.',
        textStepSpecialtyText1: 'Escolha a especialidade',
        textStepSpecialtyText2: 'Escolha o dia e horário',
        textStepSpecialtyText3: 'Descreva brevemente o motivo da consulta',
        textStepSpecialtyText4: 'Confirme os dados',
        textStepSpecialtyText5: 'Anexe exames (se tiver)',
        textStepSpecialtyText6: 'Você pode anexar imagens ou pdf',
        textStepSpecialtyText7:
          'Para iniciar o agendamento, escolha abaixo a especialidade desejada e clique em próximo.',
        textStepSpecialtyTextPayment: 'Realize o pagamento',
        textStepTitleConfirm: 'Confirmação',
        textStepTitleExams: 'Exames',
        textStepTitlePayment: 'Pagamento',
        textStepTitleReason: 'Motivo',
        textStepTitleSelectDay: 'Data/Horário',
        textStepTitleSpecialty: 'Especialidade',
        time: 'Horário',
      },
      waitingroom: {
        room: 'Sala de Espera',
      },
    },
    testConnectivity: {
      audio: {
        bad: 'Conectividade do áudio ruim',
        fair: 'Conectividade do áudio razoável',
        good: 'Conectividade do áudio boa',
        testing: 'Testando o Áudio',
      },
      connection: {
        failure: 'Falha nos testes de conexão',
        OK: 'Conectado com sucesso em nossos serviços',
        testing: 'Testando Conexão...',
      },
      error: {
        APIConnectivityError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        ConnectivityError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        ConnectToSessionError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        ConnectToSessionNetworkError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        ConnectToSessionSessionIdError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        ConnectToSessionTokenError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        FailedMessagingServerTestError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        FailedToCreateLocalPublisher:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        FailedToObtainMediaDevices:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        helpCamera:
          '- Verifique na barra de endereço do seu navegador se o acesso à câmera esta bloqueado <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span>, clique no ícone e dê permissão',
        helpCameraConfig:
          '- Verifique nas configurações do seu navegador se a câmera esta bloqueada para esse aplicativo e conceda a permissão',
        helpCameraDupliciteUse:
          '- Verifique se a câmera está sendo utilizado por outro aplicativo, feche todos e acesse novamente',
        helpCameraLink:
          '- Para outros casos, <a href="https://suporte.doutoraovivo.com.br/hc/pt-br/articles/360048709214-PERMITIR-ACESSO-A-C%C3%82MERA-NO-NAVEGADOR" target="_blank" rel="noreferrer nofollow">clique aqui</a>',
        IncompleteSessionCredentialsError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        InitPublisherError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        InvalidOnUpdateCallback:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        LoggingServerConnectionError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        MediaDeviceError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        MissingOpenTokInstanceError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        MissingSessionCredentialsError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        MissingSubscriberError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        NetworkTestError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        NoAudioCaptureDevicesError:
          'Não conseguimos acessar seu microfone, precisamos desse acesso para o Teleatendimento.',
        NoVideoCaptureDevicesError:
          'Não conseguimos acessar sua câmera, precisamos desse acesso para o Teleatendimento.',
        PublishToSessionError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        PublishToSessionNetworkError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        PublishToSessionNotConnectedError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        PublishToSessionPermissionOrTimeoutError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        QualityTestError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        SubscriberGetStatsError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        SubscribeToSessionError:
          'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
        title: 'Possíveis Correções:',
        UnsupportedBrowser: 'Navegador de Internet não suportado',
      },
      failureDetails: 'Ver detalhes',
      video: {
        bad: 'Conectividade do video ruim',
        fair: 'Conectividade do video razoável',
        good: 'Conectividade do video boa',
        testing: 'Testando o Vídeo',
      },
    },
    title: {
      appointment: {
        cid10: 'Informe o CID10',
        description: 'Descrição do Atendimento',
        doctor: 'Profissionais',
        doctor_id: 'Profissional',
        end_date: 'Data Término',
        end_date_time: 'Data / Hora de término do Atendimento',
        end_time: 'Hora Término',
        medical_assistent: 'Profissional Assistente',
        notes: 'Notas do Atendimento',
        patient_id: 'Paciente',
        role: 'Tipo Participante',
        start_date: 'Data Início',
        start_date_time: 'Data / Hora de início do Atendimento',
        start_time: 'Hora Início',
        status_appointment: 'Status de Agendamento',
        title: 'Título do Atendimento',
        type: 'Tipo de Atendimento',
        upshot: 'Informe o desfecho do atendimento',
      },
      company: {
        birth_date: 'Data de Nascimento',
        business_contact: 'Contato de négocio',
        cell_phone: 'Celular',
        city: 'Cidade',
        cnpj: 'CNPJ',
        comission_type: 'Tipo de comissão',
        comission_value: 'Valor de comissão',
        company_id: 'Empresa',
        complement: 'Complemento',
        cpf: 'CPF',
        edit: 'Editar',
        expiration_date: 'Data de Validade',
        gender: 'Sexo',
        mail: 'E-mail',
        name: 'Nome Fantasia',
        neighborhood: 'Bairro',
        number: 'Número',
        parent_id: 'Pessoa responsável financeiramente pelo paciente',
        photo: 'Foto',
        plan_id: 'ID plano',
        region: 'Unidade Federal',
        representative_id: 'ID representante',
        social_name: 'Razão Social',
        status: 'Status',
        street: 'Rua, Avenida...',
        telephone: 'Telefone',
        zip_code: 'CEP',
      },
      doctor: {
        birth_date: 'Data de Nascimento',
        cell_phone: 'Celular',
        city: 'Cidade',
        company_id: 'Empresa',
        complement: 'Complemento',
        cpf: 'CPF',
        crm: 'CRM',
        crmState: 'Estado do Conselho Regional de Medicina',
        Filtro: 'Filtro',
        gender: 'Sexo',
        id: 'Identificador',
        mail: 'E-mail',
        medical_specialty: 'Especialidade Médica',
        name: 'Nome',
        neighborhood: 'Bairro',
        number: 'Número',
        picture: 'Foto',
        region: 'Unidade Federal',
        status: 'Status',
        street: 'Rua, Avenida...',
        telephone: 'Telefone',
        zip_code: 'CEP',
      },
      patient: {
        address: 'Endereço',
        birth_date: 'Data de Nascimento',
        cell_phone: 'Celular',
        city: 'Cidade',
        company_id: 'Empresa',
        complement: 'Complemento',
        cpf: 'CPF',
        gender: 'Sexo',
        mail: 'E-mail',
        name: 'Nome',
        neighborhood: 'Bairro',
        number: 'Numero',
        parent_id: 'Pessoa responsável financeiramente pelo paciente',
        profession: 'Profissão do paciente',
        region: 'Região',
        status: 'Status',
        street: 'Rua Avenida...',
        telephone: 'Telefone',
        zip_code: 'CEP',
      },
      plan: {
        additional_minute_recorded_allowed: 'Indica se esse plano permite minuto adicional para gravação',
        additional_minute_value: 'Valor do minuto adicional de consulta do plano',
        additional_minutes_allowed: 'Indica se esse plano permite minuto adicional para consulta',
        additional_recorded_minute_value: 'Valor do minuto adicional de gravação do plano',
        attendance_average_minutes: 'Quantidade média de minutos de consulta para apuração de contrato',
        attendance_average_minutes_recorded: 'Quantidade média de minutos de gravação para apuração de contrato',
        attendance_value: 'Valor da Consulta',
        bill_type: 'Tipo do plano',
        commission_type: 'Tipo da Comissão',
        commission_value: 'Valor da Comissão',
        description: 'Nome do Plano',
        duration_days: 'Indica a quantidade de dias de duração do plano',
        initial_attendance_quantity: 'Quantidade inicial de consultas para essa faixa',
        max_additional_minute: 'Quantidade máxima de minutos adicionais para consulta',
        max_additional_recorded_minute: 'Quantidade máxima de minutos adicionais para gravação',
        max_attendance_minutes: 'Indica a duração máxima de minutos da consulta',
        minutes_quantity: 'Quantidade de minutos de consulta do plano',
        minutes_value: 'Valor do minuto de consulta do plano',
        name: 'Nome do Plano',
        recorded_minute_value: 'Valor do minuto de gravação do plano',
        recorded_minutes_quantity: 'Quantidade de minutos de gravação do plano',
      },
      professional: {
        birth_date: 'Data de Nascimento',
        cell_phone: 'Celular',
        city: 'Cidade',
        company_id: 'Empresa',
        complement: 'Complemento',
        cpf: 'CPF',
        crm: 'CRM',
        Filtro: 'Filtro',
        gender: 'Sexo',
        id: 'Identificador',
        mail: 'E-mail',
        medical_specialty: 'Especialidade Médica',
        name: 'Nome',
        neighborhood: 'Bairro',
        number: 'Número',
        photo: 'Foto',
        region: 'Unidade Federal',
        role: 'Cargo do Profisional',
        status: 'Status',
        street: 'Rua, Avenida...',
        telephone: 'Telefone',
        zip_code: 'CEP',
      },
    },
    value: {
      active: 'Ativo',
      inactive: 'Inativo',
      no: 'Não',
      yes: 'Sim',
    },
  },
  title: {
    appointment: {
      cid10: 'Informe o CID10',
      description: 'Descrição do Atendimento',
      doctor: 'Profissionais',
      doctor_id: 'Profissional',
      end_date: 'Data Término',
      end_date_time: 'Data / Hora de término do Atendimento',
      end_time: 'Hora Término',
      medical_assistent: 'Profissional Assistente',
      notes: 'Notas do Atendimento',
      patient_id: 'Paciente',
      role: 'Tipo Participante',
      start_date: 'Data Início',
      start_date_time: 'Data / Hora de início do Atendimento',
      start_time: 'Hora Início',
      status_appointment: 'Status de Agendamento',
      title: 'Título do Atendimento',
      type: 'Tipo de Atendimento',
      upshot: 'Informe o desfecho do atendimento',
    },
    company: {
      birth_date: 'Data de Nascimento',
      business_contact: 'Contato de négocio',
      cell_phone: 'Celular',
      city: 'Cidade',
      cnpj: 'CNPJ',
      comission_type: 'Tipo de comissão',
      comission_value: 'Valor de comissão',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      edit: 'Editar',
      expiration_date: 'Data de Validade',
      gender: 'Sexo',
      mail: 'E-mail',
      name: 'Nome Fantasia',
      neighborhood: 'Bairro',
      number: 'Número',
      parent_id: 'Pessoa responsável financeiramente pelo paciente',
      photo: 'Foto',
      plan_id: 'ID plano',
      region: 'Unidade Federal',
      representative_id: 'ID representante',
      social_name: 'Razão Social',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    doctor: {
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      crm: 'CRM',
      crmState: 'Estado do Conselho Regional de Medicina',
      Filtro: 'Filtro',
      gender: 'Sexo',
      id: 'Identificador',
      mail: 'E-mail',
      medical_specialty: 'Especialidade Médica',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Número',
      picture: 'Foto',
      region: 'Unidade Federal',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    patient: {
      address: 'Endereço',
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      gender: 'Sexo',
      mail: 'E-mail',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Numero',
      parent_id: 'Pessoa responsável financeiramente pelo paciente',
      profession: 'Profissão do paciente',
      region: 'Região',
      status: 'Status',
      street: 'Rua Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    plan: {
      additional_minute_recorded_allowed: 'Indica se esse plano permite minuto adicional para gravação',
      additional_minute_value: 'Valor do minuto adicional de consulta do plano',
      additional_minutes_allowed: 'Indica se esse plano permite minuto adicional para consulta',
      additional_recorded_minute_value: 'Valor do minuto adicional de gravação do plano',
      attendance_average_minutes: 'Quantidade média de minutos de consulta para apuração de contrato',
      attendance_average_minutes_recorded: 'Quantidade média de minutos de gravação para apuração de contrato',
      attendance_value: 'Valor da Consulta',
      bill_type: 'Tipo do plano',
      commission_type: 'Tipo da Comissão',
      commission_value: 'Valor da Comissão',
      description: 'Nome do Plano',
      duration_days: 'Indica a quantidade de dias de duração do plano',
      initial_attendance_quantity: 'Quantidade inicial de consultas para essa faixa',
      max_additional_minute: 'Quantidade máxima de minutos adicionais para consulta',
      max_additional_recorded_minute: 'Quantidade máxima de minutos adicionais para gravação',
      max_attendance_minutes: 'Indica a duração máxima de minutos da consulta',
      minutes_quantity: 'Quantidade de minutos de consulta do plano',
      minutes_value: 'Valor do minuto de consulta do plano',
      name: 'Nome do Plano',
      recorded_minute_value: 'Valor do minuto de gravação do plano',
      recorded_minutes_quantity: 'Quantidade de minutos de gravação do plano',
    },
    professional: {
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      crm: 'CRM',
      Filtro: 'Filtro',
      gender: 'Sexo',
      id: 'Identificador',
      mail: 'E-mail',
      medical_specialty: 'Especialidade Médica',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Número',
      photo: 'Foto',
      region: 'Unidade Federal',
      role: 'Cargo do Profisional',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
  },
};

export default ptBR;
